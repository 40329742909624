import axios from "axios";

export const getTotalUsers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/total-user`,
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const getAdmins = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/list-admin`,
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const getTotalValidators = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/total-validators`,
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const getUsers = async (type, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/list-user?sub_type=${type}`,
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const getValidators = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/list-validators`,
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const getEmissionHistory = async (page, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/list-emissions?limit=12&page=Page${page}`,
      
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const getEmissionHistoryByFacility = async (token, range) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/user-total-emissions?time-range=${range}`,
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const deleteAdmin = async (admin_id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/delete-admin`,
      {
        admin_id,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const updateUserKyc = async (
  updateType,
    subscriptionPlan,
    facilityName,
    walletAddress,
    facilityDescription,
    facilityLocation,
    facilityCategory,
    sustainabilitySolution,
    quantificationMethodology,
    fuelSource,
    renewableEnergySolutions,
    governmentOrganization,
    regulatoryStatus,
    id,
  token
) => {
  console.log('id', {
    updateType,
    subscriptionPlan,
    facilityName,
    walletAddress,
    facilityDescription,
    facilityLocation,
    facilityCategory,
    sustainabilitySolution,
    quantificationMethodology,
    fuelSource,
    renewableEnergySolutions,
    governmentOrganization,
    regulatoryStatus,
    id,
  });
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/kyc/v1/update-kyc?user_id=${id}`,
      {
        updateType,
        userId: id,
        subscriptionPlan,
    facilityName,
    walletAddress,
    facilityDescription,
    facilityLocation,
    facilityCategory,
    sustainabilitySolution,
    quantificationMethodology,
    fuelSource,
    renewableEnergySolutions,
    governmentOrganization,
    regulatoryStatus
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
    return error?.response;
  }
};

export const updateUserKycUser = async (
  updateType,
  location,
  quantifiedBasedYearEmission,
  projectDescriptionDocument,
    id,
  token
) => {
  console.log('id', {
    updateType,
  location,
  quantifiedBasedYearEmission,
  projectDescriptionDocument,
    id,
  token
  });
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/kyc/v1/update-kyc?user_id=${id}`,
      {
        updateType,
        userId: id,
        location,
        quantifiedBasedYearEmission,
        projectDescriptionDocument
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
    return error?.response;
  }
};

export const registerNonGreenDevice = async (
  facilityType,
  fuelType,
  id,
  token
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  console.log({  
    facilityType,
    fuelType
  });
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/register-sensor?user_id=${id}`,
      { facilityType, fuelType },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const registerGreenDevice = async (
  facilityType,
  solarType,
  renewableSource,
  id,
  token
) => {
  console.log('id', id);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/register-sensor?user_id=${id}`,
      { facilityType, solarType, renewableSource },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const getWeeklyEmission = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/list-weekly-emission`,
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};



export const updateIotDate = async (type, date_value, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/iot/v1/admin/update-iot-dates?update=${type}`,
      {
        date_value,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};


export const getNdcRequests = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/ndc/v1/list-ndc-requests`,
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const updateNdcRequest = async (status, requestId, userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/approve-ndc-value`,
      {
        status,
        requestId,
        userId
    },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};


export const getDonors = async (page, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/donation/v1/list-donors?limit=10&page=Page${page}`,
      
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const getTotalDonationBalance = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/donation/v1/total-donation-balance`,
      
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};


export const getCCRTByMonth = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/ccrt/v1/total-trades`,
      
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const getTotalCCRTBalance = async (token) => { 
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/donation/v1/total-donation-spent`,
      
      config
    );

    return res; // this value is in niara
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const getCcrtTrades = async (page, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/ccrt/v1/list-trades?limit=10&page=Page${page}`,
      
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

/** Website APIs **/
export const createBlogPost = async (title, intro, content, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/create-blog`,
      {
        title,
        intro,
        content,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};


export const getBlogPosts = async (page = 1) => {
  console.log(page);
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/open/v1/get-blog-posts?limit=2&page=Page${page}`,
      // config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const getDemoRequests = async (token, page = 1) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/get-demo-request?limit=10&page=Page${page}`,
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const verifyDemoRequests = async (
  id,
  token
) => {
  console.log('id', id);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/verify-demo-request?demo_id=${id}`,
      { approvalStatus: "approved"},
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const sendEmail = async (
  email,
  title,
  body,
  token
) => {
  console.log(
    "email",
    email,
  "title",
  title,
  "body",
  body,
  "token",
  token
  );
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/send-email`,
      { email, title, body },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const getTemplateSubmission = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/onboarding-template?demo_id=${id}`,
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
/** Website APIs **/




