import { useEffect, useState } from "react";
import SideBar from "../../components/AdminDashboard/SideBar";
import "../../styles/AdminDashboard/CarbonCredit.scss";
import { getUsers, getValidators } from "../../apis/AdminDashboard/users";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Search from "../../components/AdminDashboard/Search";
import { useNavigate } from "react-router-dom";

import {
  getPlantsData as greenBasicGetAllEmissionData,
  getStaticAttributes as greenBasicGetStaticAttributes,
} from "../../instantiateTransaction/basicGreenProject";
import {
  getPlantsData as infraBasicGetAllEmissionData,
  getStaticAttributes as infraBasicGetStaticAttributes,
} from "../../instantiateTransaction/basicInfrastructureChange";
import {
  getPlantsData as greenGovernanceGetAllEmissionData,
  getStaticAttributes as greenGovernanceGetStaticAttributes,
} from "../../instantiateTransaction/governanceGreenProject";

import {
  getPlantsData as governanceInfraGetAllEmissionData,
  getStaticAttributes as governanceInfraGetStaticAttributes,
} from "../../instantiateTransaction/gorvernanceInfrastructureChange";

import {
  getPlantsData as governanceMonitoringGetAllEmissionData,
  getStaticAttributes as governanceMonitoringGetStaticAttributes,
} from "../../instantiateTransaction/governanceCarbonMonitoring";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { issueCarbonCredit } from "../../apis/General/web3/shared";
import { getKyc } from "../../apis/General/users";
import { getDatabase, ref, onValue, push } from "firebase/database";

const CarbonCredit = () => {
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [issuanceLoading, setIssuanceLoading] = useState(false);
  const [greenBasicFacilities, setGreenBasicFacilities] = useState([]);
  const [infraBasicFacilities, setInfraBasicFacilities] = useState([]);
  const [greenGovernanceFacilities, setGreenGovernanceFacilities] = useState(
    []
  );
  const [infraGovernanceFacilities, setInfraGovernanceFacilities] = useState(
    []
  );
  const [monitoring, setMonitoring] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState([]);
  const db = getDatabase();

  const getValidationData = async () => {
    setLoading(true);
    try {
      const response6 = await greenBasicGetAllEmissionData(0, 20);
      console.log("response6", response6);

      setGreenBasicFacilities(response6?.getAllInfo);

      const response7 = await infraBasicGetAllEmissionData(0, 20);
      console.log("response7", response7);

      setInfraBasicFacilities(response7?.getAllInfo);

      const response8 = await greenGovernanceGetAllEmissionData(0, 20);
      console.log("response8", response8);

      setGreenGovernanceFacilities(response8?.getAllInfo);

      const response9 = await governanceInfraGetAllEmissionData(0, 20);
      console.log("response9", response9);

      setInfraGovernanceFacilities(response9?.getAllInfo);

      // const response10 = await governanceMonitoringGetAllEmissionData(0, 20);

      // setMonitoring(response10?.getAllInfo);
    } catch (error) {
      console.log("ERROR", error);
      // toast.error("Something went wrong");
    }
    setLoading(false);
  };

  useEffect(() => {
    getValidationData();
  }, []);

  const handleCarbonCreditIssuance = async (index, facility) => {
    console.log("facility", facility);
    setCurrentIndex(index);
    setIssuanceLoading(true);
    try {
      // if (facility?.isCarbonCreditIssued) {
      //   alert(
      //     "No new data available for carbon credit issuance for this facility"
      //   );
      // } else if (!facility?.isValidated) {
      //   alert("Validation has not been carried out for this Facility's data");
      // } else if (!facility?.isVerified) {
      //   alert("Verification has not been carried out for this Facility's data");
      // } else if (facility?.isRejected) {
      //   alert("This Facility's data failed Validation or Verification");
      // }
      //  else {
      let response;
      let response3;

      if (
        facility?.scenario === "infrastructure-change"
        //  &&
        // facility?.subscriptionPlan === "basic"
      ) {
        response = await infraBasicGetStaticAttributes(facility?.plantAddress);
        response3 = await issueCarbonCredit(
          facility?.plantAddress,
          "infrastructure-change",
          "basic",
          auth?.token
        );
      } else if (
        facility?.scenario === "green"
        // &&
        // facility?.subscriptionPlan === "basic"
      ) {
        response = await greenBasicGetStaticAttributes(facility?.plantAddress);
        response3 = await issueCarbonCredit(
          facility?.plantAddress,
          "green",
          "basic",
          auth?.token
        );
      }
      // if (
      //   facility?.scenario === "infrastructure-change"
      //   // &&
      //   // facility?.subscriptionPlan === "governance"
      // ) {
      //   response = await governanceInfraGetStaticAttributes(
      //     facility?.plantAddress
      //   );
      //   response3 = await issueCarbonCredit(
      //     facility?.plantAddress,
      //     "infrastructure-change",
      //     "governance",
      //     auth?.token
      //   );
      // } else if (
      //   facility?.scenario === "green"
      //   //  &&
      //   // facility?.subscriptionPlan === "governance"
      // ) {
      //   response = await greenGovernanceGetStaticAttributes(
      //     facility?.plantAddress
      //   );
      //   response3 = await issueCarbonCredit(
      //     facility?.plantAddress,
      //     "green",
      //     "governance",
      //     auth?.token
      //   );
      // }
      // else if (facility?.scenario === "carbon-monitoring") {
      //   response = await governanceMonitoringGetStaticAttributes(
      //     facility?.plantAddress
      //   );
      //   response3 = await issueCarbonCredit(
      //     facility?.plantAddress,
      //     "carbon-monitoring",
      //     "governance",
      //     auth?.token
      //   );
      // }
      else {
        alert("Invalid Scenario");
      }

      console.log("response3", response3);
      if (response3?.data?.statusCode === 200) {
        toast.success("CCRT Issued Successfully");
        push(ref(db, "notifications"), {
          type: "Carbon credit issued",
          read: false,
          user:
            facility?.scenario === "green"
              ? response?.staticAttributes[5]
              : response?.staticAttributes[6],
          timestamp: Date.now(),
        });
        push(ref(db, "events"), {
          type: "Carbon Credits Transferred",
          facilityType: "Green Project",
          addressToRecord: facility?.plantAddress,
          creditIssued: 5.71,
          scenario: "green",
          timestamp: Date.now(),
          read: false,
        });
        getValidationData();
      } else if (response3?.data?.errorMessage) {
        toast.error(response3?.data?.errorMessage);
      } else {
        toast.error("Something went wrong");
      }
      // }
    } catch (error) {
      console.log("ERROR", error);
      toast.error("Something went wrong");
    }
    setIssuanceLoading(false);
  };

  return (
    <div className="admin_carbon-credit">
      <div className="sidebar_container">
        <SideBar />
      </div>

      <div className="main">
        <Search />
        <div className="title">CCRT Issuance</div>

        <div className="carbon-credit-table">
          <div className="carbon-credit-table__header">CCRT</div>

          <div className="carbon-credit-table__label">
            <div>Company Name</div>
            <div>CO2 Tracked</div>
            <div>Baseline</div>
            <div>Energy Consumed</div>
            <div>CCRT</div>
          </div>
          {greenBasicFacilities?.map((facility, index) => (
            <div className="carbon-credit-table__item">
              <div>{facility?.organizationName}</div>
              <div>
                {facility?.emissionData?.length > 0 &&
                  ethers.formatEther(
                    facility?.emissionData?.totalGHGEmission?.toString()
                  )}
                tCO2e
              </div>
              <div>
                {facility?.emissionData?.length > 0 &&
                  ethers.formatEther(
                    facility?.emissionData?.quantifiedGHGEmissionsInBaselineScenario?.toString()
                  )}
                tCO2e
              </div>
              <div>
                {facility?.emissionData?.length > 0 &&
                  ethers.formatEther(
                    facility?.emissionData?.energyConsumptionInKWh?.toString()
                  )}
                tCO2e
              </div>
              <button
                onClick={() => handleCarbonCreditIssuance(index, facility)}
              >
                {issuanceLoading && currentIndex === index
                  ? "Loading..."
                  : "Issue CCRT"}
              </button>
            </div>
          ))}
          {infraBasicFacilities?.map((facility, index) => (
            <div className="carbon-credit-table__item">
              <div>{facility?.organizationName}</div>
              <div>
                {facility?.emissionData?.length > 0 &&
                  ethers.formatEther(
                    facility?.emissionData?.totalGHGEmissions?.toString()
                  )}
                tCO2e
              </div>
              <div>
                {facility?.emissionData?.length > 0 &&
                  ethers.formatEther(
                    facility?.emissionData?.quantifiedGHGEmissionsInBaselineScenario?.toString()
                  )}
                tCO2e
              </div>
              <div>
                {facility?.emissionData?.length > 0 &&
                  ethers.formatEther(
                    facility?.emissionData?.directStationaryEmissionsValue?.toString()
                  )}
                tCO2e
              </div>
              <button
                onClick={() => handleCarbonCreditIssuance(index, facility)}
              >
                {issuanceLoading && currentIndex === index
                  ? "Loading..."
                  : "Issue CCRT"}
              </button>
            </div>
          ))}
          {greenGovernanceFacilities?.map((facility, index) => (
            <div className="carbon-credit-table__item">
              <div>{facility?.organizationName}</div>
              <div>
                {facility?.emissionData?.length > 0 &&
                  ethers.formatEther(
                    facility?.emissionData?.totalGHGEmission?.toString()
                  )}
                tCO2e
              </div>
              <div>
                {facility?.emissionData?.length > 0 &&
                  ethers.formatEther(
                    facility?.emissionData?.quantifiedGHGEmissionsInBaselineScenario?.toString()
                  )}
                tCO2e
              </div>
              <div>
                {facility?.emissionData?.length > 0 &&
                  ethers.formatEther(
                    facility?.emissionData?.energyConsumptionInKWh?.toString()
                  )}
                tCO2e
              </div>
              <button
                onClick={() => handleCarbonCreditIssuance(index, facility)}
              >
                {issuanceLoading && currentIndex === index
                  ? "Loading..."
                  : "Issue CCRT"}
              </button>
            </div>
          ))}
          {infraGovernanceFacilities?.map((facility, index) => (
            <div className="carbon-credit-table__item">
              <div>{facility?.organizationName}</div>
              <div>
                {facility?.emissionData?.length > 0 &&
                  ethers.formatEther(
                    facility?.emissionData?.totalGHGEmissions?.toString()
                  )}
                tCO2e
              </div>
              <div>
                {facility?.emissionData?.length > 0 &&
                  ethers.formatEther(
                    facility?.emissionData?.quantifiedGHGEmissionsInBaselineScenario?.toString()
                  )}
                tCO2e
              </div>
              <div>
                {facility?.emissionData?.length > 0 &&
                  ethers.formatEther(
                    facility?.emissionData?.directStationaryEmissionsValue?.toString()
                  )}
                tCO2e
              </div>
              <button
                onClick={() => handleCarbonCreditIssuance(index, facility)}
              >
                {issuanceLoading && currentIndex === index
                  ? "Loading..."
                  : "Issue CCRT"}
              </button>
            </div>
          ))}
          {/* {monitoring?.map((facility, index) => (
            <div className="carbon-credit-table__item">
              <div>{facility?.organizationName}</div>
              <div>
                {facility?.emissionData?.length > 0 &&
                  ethers.formatEther(
                    facility?.emissionData?.totalGHGEmissions?.toString()
                  )}
                tCO2e
              </div>
              <div>
                {facility?.emissionData?.length > 0 &&
                  ethers.formatEther(
                    facility?.emissionData?.quantifiedGHGEmissionsInBaselineScenario?.toString()
                  )}
                tCO2e
              </div>
              <div>
                {facility?.emissionData?.length > 0 &&
                  ethers.formatEther(
                    facility?.emissionData?.directStationaryEmissionsValue?.toString()
                  )}
                tCO2e
              </div>
              <button
                onClick={() => handleCarbonCreditIssuance(index, facility)}
              >
                {issuanceLoading && currentIndex === index
                  ? "Loading..."
                  : "Issue CCRT"}
              </button>
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
};

export default CarbonCredit;
