import Header from "../../../components/shared/Header";
import "../../../styles/shared/legal/TermsAndConditions.scss";
import Image from "../../../images/image 96.png";
import { useState } from "react";
import Footer from "../../../components/shared/Footer";
export default function TermsAndConditions() {
  const [open, setOpen] = useState(false);
  return (
    <div className="terms">
      <Header />
      <div className="hero">
        <div className="title">Terms & Conditions</div>
        <div className="subtitle">
          Take a moment to get familiar with the usage rules. Your agreement takes effect as soon as you start using the website.
          It's essential to know and follow the terms laid out here.
        </div>
      </div>
      <div className="content">
        <div className="contentParagraph subtitleBold">
        These Terms and Conditions govern your use of the CloseCarbon platform, website, and related services (collectively referred to as the "Platform"). 

        <br />
        <br />

        In this Terms and Conditions “Netzence”, “CloseCarbon”, “The Company”, “We”, “Us”, “Our” refers to Netzence Sustainability Limited, and “User”, “Facility”, “You”, “Your” shall refer to a User of this platform. 
        <br />
        <br />
        By accessing or using the Platform, you agree to comply with and be legally bound by these Terms. If you do not agree with these Terms, please do not use the Platform. If you choose not to accept this agreement, you are expressly prohibited from using the CloseCarbon platform and must discontinue use immediately.
        <br />
        <br />
        For any inquiries about this agreement, feel free to reach out to us via email at support@closecarbon.com
        </div>
        <div className="contentTitle">Acceptance of Terms</div>
        <div className="contentParagraph">
        By accessing or using this Platform, you confirm that you have read, understood, and agreed to these Terms, including our Privacy Policy. These Terms constitute a legally binding agreement between you and Netzence Sustainability Limited for use of the CloseCarbon Platform.
        </div>
        <div className="contentTitle">LEGAL CAPACITY</div>

        <div className="contentParagraph">
        You must be at least 18 years old or the legal age in your jurisdiction to use the Platform. If you are accessing the Platform on behalf of a company or organization, you represent and warrant that you have the authority to bind that entity to these Terms.
        </div>
        <div className="contentTitle">USE OF THE PLATFORM</div>
        <div className="contentSubtitle">Registration</div>
        <div className="contentParagraph">
        To fully utilize the Platform's features, you may need to create an account. You agree to provide accurate and up-to-date information during the registration process.
        </div>
       

        <div className="contentTitle">
        SECURITY MEASURES
        </div>
        <div className="contentParagraph">
        CloseCarbon maintains physical, technical, and administrative procedures to protect the data we collect and to secure it from improper and unauthorized use. We work hard to protect data in our custody and control from loss, misuse, and unauthorized access, disclosure, modification, or destruction, and to use industry-standard security measures to ensure an appropriate level of security in light of reasonably available methods in relation to the risks and nature of the information we collect.
        </div>
        <div className="contentTitle">PROHIBITED ACTIVITIES</div>
        <div className="contentParagraph">
        You agree not to:
       <ul>
        <li>Use the Platform for any unlawful or harmful purposes.</li>
        <li>Impersonate any person or entity or misrepresent your affiliation with any entity.</li>
        <li>Attempt to gain unauthorized access to the Platform, its servers, or networks.</li>
        <li>Interfere with the proper functioning of the Platform.</li>
        <li>Engage in any activity that could harm the reputation or integrity of CloseCarbon.</li>
        <li>Use our Products or Services if you are not eligible to use our Products
or Services and will not use our Products or Services other than for their intended purpose. 
</li>
        </ul>

        Furthermore, in connection with our Products or Services you agree not to;

        <ul>
          <li>Violate any applicable law, contract, intellectual property right, or commit a tort.</li>
          <li>Engage in any harassing, threatening, intimidating, predatory, or stalking conduct.</li>
          <li>Use or attempt to use another user’s account or information without
authorization from that User and Company.
</li>
<li>
Impersonate or post on behalf of any person or entity or otherwise misrepresent your affiliation with a person or entity;
</li>
<li>Sell or resell our Products or Services.</li>
<li>Copy, reproduce, distribute, publicly perform, or publicly display all or portions of our Products or Services</li>
<li>Modify our Products or Services, remove any proprietary rights notices or markings, or otherwise make any derivative works based upon our Services;</li>
<li>
Use our Products or Services in any manner that could interfere with, disrupt, negatively affect, or inhibit other Users from fully enjoying our Products or Services or that could damage, disable, overburden, or impair the functioning of our Products or Services in any manner. 
</li>

<li>Reverse-engineer any aspect of our Products or Services or do anything that might discover or reveal source code, or bypass or circumvent measures employed to prevent or limit access to any part of our Products or Services;</li>
<li>Use any data mining, robots, or similar data gathering or extraction methods designed to scrape or extract data from our Products or Platform.</li>
<li>Develop or use any applications or software that interact with our Products or Services without our prior written consent;</li>
<li>Link to any online portion of the Products or Services in a manner that damages or exploits, in our sole discretion, our reputation or suggests any form or association, approval, or endorsement by the Company; or
</li>
<li>Use our Products or Services for any illegal or unauthorized purpose, or engage
in, encourage, or promote any activity that violates these Terms.
</li>
        </ul>

        Enforcement of this Section is solely at the Company’s discretion, and failure to
enforce this section in some instances does not constitute a waiver of our right to
enforce it in another instance.

        
        </div>
        <div className="contentTitle">CARBON EMISSION TRACKING AND INCENTIVES</div>
        <div className="contentParagraph">
          <span className="subtitleBold">Accuracy of Data</span><br />
          Users must provide accurate and truthful information about wallet address or plant address for carbon tonne to be generated during the KYC process.
          <span className="subtitleBold">Carbon incentives</span><br />
          CloseCarbon does not guarantee the conversion of carbon credits or any financial benefit. We provide tools to help track emissions and assist with the process, but the results may vary depending on external factors.
        </div>
        <div className="contentTitle">CHARGES</div>
        <div className="contentParagraph">
        The CloseCarbonReductionTonne (CCRT) is a reward system designed to support environmental sustainability initiatives by facilitating controlled tonne transfers. The primary recipient of this tonne is Netzence Sustainability, but other approved entities can also receive them.

        <br />
        <br />

        CloseCarbon Reduction Tonne (CCRT) is issued by CloseCarbon after confirming that carbon emission reduction data meets specific standards. The CCRT does not entitle a user to a stake in the Company or the CloseCarbon Platform, neither does it serve as a security or equity of any sort, it is only an incentive given as reward for the reduction of Carbon Emissions.

        <br />
        <br />

        One CCRT represents approximately one tonne of carbondioxide (CO2) that has been emitted. For every CCRT earned by the User/Facility, the following rates and charges are applicable and so divided:
<br />
<br />
        User/Facility: 70% (The users) 
        <br />
        Netzence: 23% (Our company)
        <br />
        CCV or Sub-CCV: 7% (These are third-party bodies that verify the carbon emission data).

        <br />
        <br />
        The CloseCarbon Platform reserves the exclusive right to update, change, amend and review these rates and charges with subsequent notice to the user. By agreeing to these Terms and Conditions, you agree and consent to these rates and charges and they become applicable to you.

        <br />
        <br />
        The CloseCarbon Platform reserves the exclusive right to update, change, amend and review these rates and charges with subsequent notice to the user. By agreeing to these Terms and Conditions, you agree and consent to these rates and charges and they become applicable to you.
        </div>
        <div className="contentTitle">THIRD PARTY SERVICES</div>
        <div className="contentParagraph">
        Our Product and Services which include calculating Users’ carbon emissions reduction, issuing and exchanging CCRT etc., rely on or interoperate with third-party products and services, which includes verifying calculated carbon emission, internet and mobile operators (collectively called “Third-Party Services”). Some third-party service providers whose services we require for the dispensing of our services are;
        <ol>
          <li>CloseCarbon Verifiers (CCV): An accredited verification body registered on CloseCarbon that verifies the reduction of carbon emissions of a User.</li>
          <li>Sub-CloseCarbon Verifiers (Sub-CCV): A verifier registered under a CCV.</li>
          <li>Network Service Providers: Provides stable internet services for real time transmission of data to the Company</li>
          <li>Payment Service Providers: Provides electronic banking services between the Company and a User or Donor  </li>
        </ol>

        <br />
        <br />
        These Third-Party Services are not under our management and thus beyond our control, however their operation may impact the use and reliability of our Services and Products. You acknowledge and agree that:
        <ul>
          <li>The use of our Product and Services are dependent on third-party service providers and </li>
          <li>These Third-Party Services may not operate reliably 100% of the time, which may impact the way our Products or Services operate.</li>
          <li>We have no obligation to monitor Third-Party Services, and we may block or disable your access to any Third-Party Services (in whole or part) through our Products or Services at any time. </li>
          <li>
          Your access to and use of such Third-Party Services may be subject to additional terms, conditions, and policies applicable to such Third-Party Service Providers (including terms of service or privacy policies of the providers of such Third-Party Service Providers). You are responsible for any consent you give to the Privacy Policies or the Terms and Conditions of these Third-Party Service Providers in the course of using our Product and Services.
          </li>
        </ul>
        </div>
        <div className="contentTitle">REFERRER TERMS</div>
        <div className="contentSubtitle">Authorized Referrers</div>

        <div className="contentParagraph">
        This clause applies to individuals expressly authorized by Netzence in writing to carry out referral activities, thus, making them “Authorized Referrers”. The relationship between Netzence and these Authorized Referrers does not create a master-servant relationship or an agency relationship. Netzence may revoke this authorization at any time and for any reason.
        </div>
        <div className="contentSubtitle">Customer Agreement</div>
        <div className="contentParagraph">
        An Authorised referrer is solely and exclusively responsible for the terms of agreement between himself and the Prospect and their overall business relationship.
        </div>
        <div className="contentTitle">CARBON EMISSIONS DATA SHARING</div>
        <div className="contentParagraph">
        The United Nations Framework Convention on Climate Change via The Paris Agreement requires Party States to prepare, communicate and maintain successive Nationally Determined Contributions Value as a mechanism to manage and calculate carbon emissions of party states and combat dangerous climate change. The Federal Republic of Nigeria is a party to this Agreement, thus Netzence may be required to transmit calculated carbon emission data to the Federal Republic of Nigeria or to the UNFCCC, thus, by agreeing to this Terms and Conditions, you consent that your carbon emissions data may be shared with these entities where the Company is under an obligation to do so.
        </div>
        <div className="contentTitle">VOLUNTARY DONATION POOL</div>
        <div className="contentSubtitle">Intention</div>
        <div className="contentParagraph">
        The Donor agrees that there is a clear and voluntary intention on the part of the Donor to transfer ownership of the donated funds to the Company without any expectation to receive something or incentive in return for the donation made either in cash, kind or service.
        <br />
        <br />
        The Donor agrees that the donation made under this pool does not constitute a payment for a stake in the company as security, equity or debt of any sort. 
        <br />
        <br />
        The Donor agrees that this donation is not made as a result of coercion, undue influence or fraud.
        </div>
        <div className="contentTitle">RELATIONSHIP OF THE PARTIES</div>
        <div className="contentParagraph">The Parties agree that the relationship with respect to any donation contemplated herein is one of “Donee” and “Donor” only and no provision of this agreement shall be construed to create any other type of status or relationship between the Parties with respect to such donation.</div>
        <div className="contentTitle">PROHIBITED PERSONS</div>

        <div className="contentParagraph">
        INDIVIDUALS, GROUPS, ASSOCIATIONS AND COMPANIES LISTED ON 
        <ol>
          <li>THE UNITED NATIONS SANTIONS LIST</li>
          <li>THE NIGERIAN SANCTION LIST</li>
          <li>THE SPECIALLY DESIGNATED NATIONALS AND BLOCKED PERSONS LIST</li>
        </ol>
        ARE EXPRESSLY AND STRICTLY PROHIBITED FROM DONATING ON THIS PLATFORM
        </div>
        <div className="contentTitle">CURRENCY</div>
        <div className="contentParagraph">
        The acceptable currencies for Donation on this Platform are The Nigerian Naira (NGN), The Ghanaian Cedis (GHC) and The United States Dollar (USD). For your convenience, please note that we only accept donations via bank transfers or electronic transfers.
        </div>
        <div className="contentTitle">EXPENSES</div>
        <div className="contentParagraph">
        The Donor agrees to be responsible for all expenses incurred in connection with the donation, including but not limited to bank transfer, bank charges, and any other applicable charges accrued in the course of a donation.
        </div>

        <div className="contentTitle">DELIVERY</div>
        <div className="contentParagraph">
        The Donor agrees to deliver the donation via electronic bank transfers, upon receipt and confirmation of a donation the Company would subsequently issue a downloadable Certificate of Donation to the Donor on the Platform.
        </div>

        <div className="contentTitle">PRIVACY AND DATA</div>

        <div className="contentSubtitle">Privacy</div>
        <div className="contentParagraph">
        CloseCarbon respects your privacy and handles your personal data in accordance with our Privacy Policy and the Nigerian Data Protection Act 2023, The NDPR and the GDPR
        </div>
        <div className="contentSubtitle">Data Ownership</div>
        <div className="contentParagraph">
        You retain ownership of the data you submit to the Platform. CloseCarbon may use aggregated, anonymized data for research and improvement purposes. You may read our Privacy Policy here……………
        </div>

        <div className="contentTitle">CONTENT OWNERSHIP</div>
        <div className="contentParagraph">
        All content provided on the Platform, including text, graphics, logos, source codes and software etc., is the property of Netzence Sustainability Ltd and protected by Copyright Laws. You may not use, reproduce, or distribute this content without prior written consent from the Company.
          </div>

          <div className="contentTitle">LIMITATION OF LIABILITY</div>

          <div className="contentParagraph">
          In the absence of negligence, bad faith or wilful misconduct by the Company/CloseCarbon Platform, we shall not be liable to the user or to any other person, including any party claiming by, through or on behalf of an authorized User, for any losses, liabilities, damages, costs or expenses arising out of any error or mistaken data or other information provided to us by the User or any other person acting on behalf of the User.
          <br />
CloseCarbon shall not be liable for any direct, indirect, incidental, consequential, or punitive damages or loss arising out of your use or inability to use the Platform.

            </div>

            <div className="contentTitle">TERMINATION, SUSPENSION OR MODIFICATIONS TO THE SERVICES</div>

            <div className="contentParagraph">
            The Company reserves the exclusive right to modify our Services or to suspend or terminate the provision of all or part of our Products or Services to you at any time. We may provide you with notice in advance of the suspension or discontinuation of all or part of our Products or Services, such as by sending an email or providing a notice through our Products or Services. All modifications and additions to the Products and Services will be governed by the Terms, Updated or Supplemental Terms, unless otherwise expressly stated by The Company in writing. 
            <br />You also have the right to stop using our Products and Services at any time, especially where you do not agree with our updated or supplementary terms, and you may terminate your consent to these Terms by ceasing use of our Products and Services. We are not responsible for any loss or harm related to your inability to access or use our Services.
              </div>

              <div className="contentTitle">GOVERNING LAW</div>

              <div className="contentParagraph">
              These Terms shall be governed by and construed in accordance with the laws of Federal Republic of Nigeria. Any disputes arising from these Terms shall first be subject to negotiation between the parties and subsequently to the jurisdiction of the courts in Nigeria
              </div>

              <div className="contentTitle">DISCLAIMER OF WARRANTIES</div>
              <div className="contentParagraph">
              The Platform is provided "as is," without warranties of any kind, to any user registered under the platform, whether express or implied. CloseCarbon makes no representations or warranties regarding the accuracy or completeness of the information on the Platform.
              </div>

              <div className="contentTitle">SEVERABILITY</div>
              <div className="contentParagraph">
              Where any part of these Terms is found to be unenforceable or unlawful by reason of an existing law, rules or regulation, or where it is held by a Nigerian court of Law to be unconscionable, then;
              </div>
              <ul>
                <li>The unenforceable or unlawful provision alone will be severed from these Terms</li>
                <li>The severance of the unenforceable or unlawful provision will have no impact whatsoever on the remainder of these Terms</li>
                <li>
                	The unenforceable or unlawful provision may be revised to the extent required to render the Terms enforceable or 
                </li>
              </ul>
        <div className="contentTitle">FEEDBACK</div>
        <div className="contentParagraph">
        You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the Platform or the Services provided by you to us are non-confidential and shall become our sole property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of Feedback for any lawful purpose, commercial purpose or otherwise, without acknowledgment or compensation to you. You hereby waive all moral rights to any such Feedback, and you hereby warrant that any such Feedback are original to you or that you have the right to submit such Feedback. You agree that there shall be no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right(s) in your Feedback.
        </div>
     
        <div className="contentTitle">UPDATE ON TERMS AND CONDITION</div>
        <div className="contentParagraph">
        We reserve the sole and exclusive right to change or modify all or portions of these Terms of Service at any time. Where possible we would give a notice of every and any changes to these Terms of Service. Changes may be made on short or no notice when such change is required by Law.
        <br />
        When we make any changes to these Terms we will post the revised Terms on this platform. Your continued use of our service/platform after the date of any changes constitutes your acceptance of the new or updated Terms of Service, if you do not wish to accept the new Terms of Service you must discontinue the use of the platform.
        </div>
      </div>
      <Footer />
    </div>
  );
}
