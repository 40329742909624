import Header from "../../../components/shared/Header";
import "../../../styles/shared/legal/PrivacyPolicy.scss";
import Image from "../../../images/image 96.png";
import { useEffect, useState } from "react";
import Footer from "../../../components/shared/Footer";
export default function PrivacyPolicy() {
  const [open, setOpen] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const handleFaqOpen = (number) => {
    if (!numbers.includes(number)) {
      let temp = numbers;
      temp.push(number);
      setNumbers([...temp]);
    } else {
      let temp = numbers;
      temp.splice(temp.indexOf(number), 1);
      setNumbers([...temp]);
    }
  };

  useEffect(() => {
    // console.log('new number', numbers);
  }, [numbers]);
  return (
    <div className="privacy">
      <Header />
      <div className="hero">
        <div>
          <div className="title">Privacy policy</div>
          <div className="subtitle">
            At CloseCarbon, Your Trust is our commitment and we respect your
            privacy.
          </div>
        </div>
        <div>
          <img src={Image} alt="" />
        </div>
      </div>
      <div className="content">
        <div className="contentParagraph subtitleBold">
        This Privacy Policy governs your use of this website and how Netzence Sustainability Limited (Netzence) can process the information we collect pursuant to the provisions of the Nigerian Data Protection Act 2023 (“NDPA 2023”), the Nigerian Data Protection Regulations 2019 (“NDPR 2019”), and the General Data Protection Rules (GDPR). Personal Data under this Privacy Policy is as defined in the NDPA 2023. 
In this Privacy Policy “Netzence”, “CloseCarbon”, “The Company”, “We”, “Us”, “Our” refers to Netzence Sustainability Limited, and “User”, “You”, “Your” shall refer to a User of this platform. 
Netzence maintains the following privacy principles which govern how we collect, use, record, organise, structure, store, adapt, alter, retrieve, consult, disclose, disseminate, align, combine, restrict, erase or destroy and generally manage your personal data (the processing of your personal data). 

        </div>
        <div className="contentTitle">TERMS OF USE</div>
        <div className="contentParagraph">
        You are required to comply with the provisions of our Terms and Conditions in relation to the information provided and your use of the website.<br />
          <span className="contentParagraph__bold">
            Lawful Bases for Collecting and Processing Your Personal Data{" "}
          </span>
          <br />
          The Company processes your personal data on the following legal grounds: 
          <ul>
            <li>Where your consent as the data subject has been obtained </li>
            <li>Offering CloseCarbon services to the data subject </li>
            <li>
            For compliance with a legal obligation imposed on the data controller  
            </li>
            <li>
            Where it is necessary to protect the vital interests of a person in line with data privacy regulations 
            </li>
            <li>
            Where it is necessary for the performance of a task carried out in the public interest 
            </li>
            <li>
            Where it is in the legitimate interests of the Company (except where the interests or rights and freedoms of the data subject overrides those interests) 
            </li>
          </ul>
        </div>
        <div className="contentTitle">COLLECTION OF PERSONAL DATA</div>
        <div className="contentParagraph">
        CloseCarbon is a platform owned by Netzence Sustainability Company incorporated under the Companies and Allied Matters Act 2020 having its registered address at No. 7, Tema Street, Wuse Zone 6, Abuja, FCT, Nigeria and as a responsible business registered and recognized by the Federal Government of Nigeria, we are committed to complying with the applicable laws and regulations governing data privacy in Nigeria. The NDPA and GDPR gives you certain rights regarding our use of your personal data including the right to: 
        <ul>
          <li>Request access to the personal data we have collected about you to review, modify, or request deletion of your personal data </li>
          <li>Request that we cease processing your personal data </li>
          <li>Request for your personal data to be transferred to another organization in a commonly used and machine-readable format. </li>
        </ul>
        Kindly note that we reserve the right to charge an appropriate fee for complying with your request where applicable law allows, and we have the authority to deny your requests where they be manifestly unfounded, excessive or otherwise objectionable or unwarranted under applicable law. 
        <br />
        When you create or update an account for the Services with us, you provide us with data, which may include personal information, and any other information that you share or provide to us.  We also may collect personal information that you post, upload, store, display, transmit, or submit through our Services in any other manner. Although we will treat such information consistent with this Privacy Policy, we are not responsible for the content of any information, including Personal Information, that you provide to us, and by using our Services, you assume full responsibility for obtaining, and you represent that you have obtained, all necessary consents and permissions, where it is required, to provide such information to us.  
        <br />
        CloseCarbon may also collect other information from you related to your use of our Services and your interactions with our Services (while this information may not typically contain Personal Information, we are not responsible for the content of such information). This information includes any such information that you affirmatively provide to CloseCarbon. 
CloseCarbon receives data when submitted to or through our Services, or if you contact us (via email, telephone, written correspondence, web-based forms, or otherwise), request support, interact with our social media accounts, or otherwise communicate with CloseCarbon. 
 

        </div>
        <div className="contentTitle">WHAT DO WE NEED?</div>
        <div className="contentParagraph">
        CloseCarbon will be known as the “controller” and “processor” of the personal data you provide us.  We collect your personal information directly from you when you upload your personal data on our platform and through your continued use of our platform. 
We collect and use the personal information you provide to us as a part of our services. The personal information we collect from you may include: 
 
<ul>
  <li>Full name </li>
  <li>Financial details</li>
  <li>Email address </li>
  <li>Phone number, etc. </li>
</ul>
We collect these data from you to ensure that the service we provide to you is seamless and secure. 
<br />
You acknowledge that your use of the public blockchain technology on this platform creates a permanent immutable record of your transactions, including your wallet address, as well as any data from making payments from or receiving payments to such address.
        </div>
        <div className="contentTitle">5.	WHY DO WE NEED IT? </div>
        <div className="contentParagraph">
        We collect your basic personal information to provide you with the carbon emission reduction services you have engaged us for and in the course of processing this data we comply with the set down provisions stated in the NDPA 2023, the NDPR 2019 and the GDPR. Failure to provide the company with your personal details as a requirement for your engagement of our services may impact the quality and the extent to which we may render our services to you. 
        </div>
        <div className="contentTitle">WHAT DO WE DO WITH IT? </div>
        <div className="contentParagraph">
        We collect, store, and process your data as required by law to enable you obtain our carbon emission monitoring services. We use your data for the following: 
        <ul>
          <li>
          To make the Services available:  <br />
          To manage user requests and interactions with the Services (e.g., login and authentication, modifying settings, etc.), to facilitate hosting and back-end infrastructure for our platform, to analyse and monitor usage, and to monitor and address service performance, security, and technical issues. 

          </li>
          <li>
          Improving the Services: <br />
To test features, incorporate new features related to carbon monitoring and transparency, data analysis and research.  

          </li>
          <li>
          Support Services: <br />
          To respond to support requests and otherwise provide support for and resolve problems with the Services. 

          </li>
          <li>
          Communications: <br />
          To send service, technical, and administrative emails, messages, and other communications. Service-related communications about changes to the Services and important Services-related notices, such as maintenance and security announcements, are essential to delivery of the Services and you cannot opt-out of these communications.
          <br />
          Marketing communications, if any, about new product features, offerings, and other news about CloseCarbon are optional; you may choose whether to receive them and you may opt out by using the unsubscribe mechanism in such communications. 
          </li>
          <li>
          Account Management: <br />
          To contact you in connection with account management, feedback, and other administrative matters related to your account with us and the Services. 

          </li>
          <li>
          Security Purposes: <br />
          To help prevent and investigate security issues and abuse. 

          </li>
          <li>
          Legal Obligations: <br />
          To comply with legal obligations as required by applicable law, legal process, or regulations as described above.  

          </li>
        </ul>
        </div>

        <div className="contentTitle">SHARING OF PERSONAL DATA </div>
        <div className="contentParagraph">
        Netzence will not use or disclose your Personal Information for any purpose other than to the extent reasonably necessary to perform the Services and related support for the Services. As a matter of policy, we do not sell or rent information about you, and we will not disclose information about you in a manner inconsistent with this Privacy Policy except as required by law or government regulation. 
        <br /> 
        We cooperate with law enforcement inquiries, to enforce laws such as those regarding intellectual property rights, fraud, and other personal rights. WE HAVE A LEGAL RESPONSIBILITY TO DISCLOSE ANY INFORMATION ABOUT YOU TO LAW ENFORCEMENT, OTHER GOVERNMENT OFFICIALS IN CONNECTION WITH AN INVESTIGATION OF FRAUD, INTELLECTUAL PROPERTY INFRINGEMENT OR OTHER ACTIVITY THAT IS ILLEGAL OR MAY EXPOSE US, OR YOU, TO LIABILITY. 
        <br />
        <br />
        We may disclose your data, including Personal Information, to third party service providers when we have your express consent to do so. This includes any information that you post to your user page or otherwise on our website and/or via the Services. 
        <br />
        Netzence’s employees and contractors may have access to your data on a need to know and confidential basis to the extent necessary to render the Services and related support for the Services. We mandate such employees and contractors to treat your information in a manner consistent with this Privacy Policy. 
        <br />
        All the personal data collected from you will be held and processed securely by our facilities and/or authorised service providers (third party), where applicable.   <br />
        The User acknowledges that its personal information may be transferred to a jurisdiction outside of Nigeria, and the User consents to such transfer, provided that we shall take every effort to ensure that the cross-border transfer complies with the NDPR and the GDPR.
        <br /> 
        Your data will be confidential unless required by a legal regulatory requirement or where it is a legal basis for the processing of your personal data. 
        <br />
        We will take all reasonable actions to ensure that the personal data of all our customers are handled securely and in a controlled manner. 
        <br />
        Personal data may be shared with third parties and service providers to facilitate prompt and complete delivery of our services to you, this processing is done in accordance with the Nigerian Data Protection Act 2023, the NDPR 2019 and the GDPR 
        <br />
        In addition, we may disclose your personal data: 
        <ul>
          <li>	Where it is in accordance with due legal process, or we are required by law to so disclose. </li>
          <li>
          	To the government regulators or law enforcement authorities 
          </li>
          <li>Where we verily believe that the disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with an investigation of suspected or actual illegal activity.  </li>
          <li>
          If disclosure is necessary to protect the vital interests of a person 
          </li>
          <li>To protect our Terms and Conditions </li>
          <li>To protect our property, services and legal rights </li>
          <li>To support auditing, compliance corporate governance functions </li>
          <li>To comply with any applicable laws. </li>
        </ul>
        </div>

        <div className="contentTitle">HOW LONG DO WE KEEP IT? </div>
        <div className="contentParagraph">
        As a regulated company under legal restrictions, we will retain your personal data as may be required by regulation, after which it will be disposed of upon your written request to the company and as required under the NDPR and GDPR. Please contact <a href="mailto:info@closecarbon.com">info@closecarbon.com</a> to exercise this right.
        <br />
        Please note that where legal regulations or directives require the company to retain your personal data for a specific period, the company will have to comply with such provisions and retain your personal data for such set down period even after the end of your business relationship with us. 
          </div>

          
        <div className="contentTitle">DATA SECURITY  </div>
        <div className="contentParagraph">
        At Netzence, the security of our Users Data is our topmost priority, and we always strive to ensure that your personal data is protected against unauthorized or accidental access. We maintain this commitment to our users by implementing appropriate physical, electronic and managerial measures to safeguard and secure your personal data with us. 
Our web servers are protected behind “firewalls”, and our systems are monitored to prevent unauthorised access. We will not send confidential information to you by ordinary email, as the security of ordinary email cannot be guaranteed. 
All reasonably practical steps shall be taken to ensure that your personal data is kept secure in line with applicable Data Protection Regulations.  
 
        </div>


        <div className="contentTitle">SHARED RESPONSIBILITY  </div>
        <div className="contentParagraph">
        The company and its customers shall play an important role in protecting the user’s account against online fraud. You must ensure that your CloseCarbon Platform account details, including your username and password are not compromised by ensuring you do not knowingly or accidentally share, provide, or facilitate unauthorised use. Please do not share your CloseCarbon Platform account details like your Username and Password with any third party or allow third parties access to them. 
        <br />
        You have the responsibility of protecting your Username and Password by keeping them safe, secret and confidential. 
        <br />
        You are responsible for informing us timeously, if you think or have any reasonable indication that your CloseCarbon Platform Username and Password have been compromised, disclosed, lost or stolen and unauthorized transactions may have been conducted on your account. 
        </div>

        <div className="contentTitle">CHANGES TO PRIVACY POLICY  </div>
        <div className="contentParagraph">
        We reserve the sole and exclusive right to make changes to this Privacy Policy from time to time, in whole or in part, in our sole discretion, at any time without prior notice by posting an updated version of this Privacy Policy on the Site or when you access the Services. When we do, we will revise the “last updated” date at the beginning of the Privacy Policy. We will make commercially reasonable efforts to notify you by email, through the Services (at log-in or otherwise), and/or by posting a prominent notice on our Site that our Privacy Policy has been updated. Any changes, modifications, or updates to this Privacy Policy will become effective immediately upon posting. By continuing to use the Services, you agree to be bound by changes we make to this Privacy Policy. If you disagree with the changes to this Privacy Policy, your only remedy is to discontinue use of the Services and deactivate your account. 
        </div>
        <div className="contentTitle">REMEDIES FOR PERSONAL DATA VIOLATION   </div>
        <div className="contentParagraph">
        If your personal data is violated at any given time, you are at liberty to contact the Data Protection Officer of the Company at <a href="mailto:info@closecarbon.com.">info@closecarbon.com.</a> 
        <ul>
          <li>Within 30 days of the Company receiving a complaint we shall notify the user in line with data privacy requirements of either the company’s position concerning complaint and any action the Company has taken, can or will take in response to the complaint, or when the individual will be informed of the Company’s position on the complaint in accordance with the data privacy requirements. </li>
          <li>Remedies may include but are not limited to investigating and reporting to appropriate authorities, recovering personal data, correcting it and enhancing controls around it.  </li>
        </ul>
        </div>

        <div className="contentTitle">ENFORCEMENT </div>
        <div className="contentParagraph">
        CloseCarbon will actively monitor its relevant privacy and security practices to verify adherence to this Privacy Policy. Any agents, contractors, service providers, or other third parties subject to this Privacy Policy that CloseCarbon determines to be in violation of this Privacy Policy or applicable data protection laws will be subject to disciplinary action up to and including termination of applicable services. Kindly contact us immediately at the contact information provided under the “Contact Us” heading above if you believe there has been a material violation of this Privacy Policy. 
        </div>

        <div className="contentTitle">CHANGES TO THIS NOTICE AND UPDATE </div>
        <div className="contentParagraph">
        We update our privacy policy occasionally and will notify users of this change.
        </div>

        <div className="contentTitle">CONTACT US  </div>
        <div className="contentParagraph">
        Do not hesitate to contact us with any concerns about your account or a request on how to contact customer support for assistance. You may contact us at: <a href="mailto:support@closecarbon.com">support@closecarbon.com</a>. 
        <br />
        <br />
        To contact our Data protection officer: <a href="mailto:info@closecarbon.com">info@closecarbon.com</a>. . Note that if you contact us to assist you, for your safety and ours we may need to authenticate your identity before fulfilling your request. 
          </div>

          <div className="contentTitle">CONSENT</div>
          <div className="contentParagraph">
          By selecting ‘I Agree,’ accessing or using our services and website in any manner, you indicate to us that you have read and accepted this Privacy Policy and consent to the data practices described herein. 
          <br />
          <br />
          You represent to us that you have the legal capacity to give consent, you are aware of your privacy rights and your option to withdraw your consent at any given time.  
          <br />
          <br />
          If you do not accept this Privacy Policy and do not wish to comply with the provisions set forth herein, you may not use or access our services or website. 
            </div>

        {/* <div className="contentFaq">
          <div className="faqMainTitle">How we use your Data</div>

          <div className="faqCard">
            <div className="faqTitle pointer" onClick={() => handleFaqOpen(1)}>
              <div>1.1 Providing and Improving the Services</div>
              {numbers.includes(1) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#3B3B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              {!numbers.includes(1) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.75 4.5C11.9489 4.5 12.1397 4.57902 12.2803 4.71967C12.421 4.86032 12.5 5.05109 12.5 5.25V11H18.25C18.4489 11 18.6397 11.079 18.7803 11.2197C18.921 11.3603 19 11.5511 19 11.75C19 11.9489 18.921 12.1397 18.7803 12.2803C18.6397 12.421 18.4489 12.5 18.25 12.5H12.5V18.25C12.5 18.4489 12.421 18.6397 12.2803 18.7803C12.1397 18.921 11.9489 19 11.75 19C11.5511 19 11.3603 18.921 11.2197 18.7803C11.079 18.6397 11 18.4489 11 18.25V12.5H5.25C5.05109 12.5 4.86032 12.421 4.71967 12.2803C4.57902 12.1397 4.5 11.9489 4.5 11.75C4.5 11.5511 4.57902 11.3603 4.71967 11.2197C4.86032 11.079 5.05109 11 5.25 11H11V5.25C11 5.05109 11.079 4.86032 11.2197 4.71967C11.3603 4.57902 11.5511 4.5 11.75 4.5Z"
                    fill="#3B3B3B"
                  />
                </svg>
              )}
            </div>
            {numbers.includes(1) && (
              <div className="faqSubtitle">
                To make the Services available, to manage user requests and
                interactions with the Services (e.g., login and authentication,
                modifying settings, etc.), to facilitate hosting and back-end
                infrastructure for our platform, to analyze and monitor usage,
                and to monitor and address service performance, security, and
                technical issues.
              </div>
            )}
          </div>

          <div className="faqCard">
            <div className="faqTitle pointer" onClick={() => handleFaqOpen(2)}>
              <div>1.2 Improving the Services</div>
              {numbers.includes(2) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#3B3B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              {!numbers.includes(2) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.75 4.5C11.9489 4.5 12.1397 4.57902 12.2803 4.71967C12.421 4.86032 12.5 5.05109 12.5 5.25V11H18.25C18.4489 11 18.6397 11.079 18.7803 11.2197C18.921 11.3603 19 11.5511 19 11.75C19 11.9489 18.921 12.1397 18.7803 12.2803C18.6397 12.421 18.4489 12.5 18.25 12.5H12.5V18.25C12.5 18.4489 12.421 18.6397 12.2803 18.7803C12.1397 18.921 11.9489 19 11.75 19C11.5511 19 11.3603 18.921 11.2197 18.7803C11.079 18.6397 11 18.4489 11 18.25V12.5H5.25C5.05109 12.5 4.86032 12.421 4.71967 12.2803C4.57902 12.1397 4.5 11.9489 4.5 11.75C4.5 11.5511 4.57902 11.3603 4.71967 11.2197C4.86032 11.079 5.05109 11 5.25 11H11V5.25C11 5.05109 11.079 4.86032 11.2197 4.71967C11.3603 4.57902 11.5511 4.5 11.75 4.5Z"
                    fill="#3B3B3B"
                  />
                </svg>
              )}
            </div>
            {numbers.includes(2) && (
              <div className="faqSubtitle">
                To test features, manage landing pages, heat mapping, traffic
                optimization, data analysis and research.
              </div>
            )}
          </div>
          <div className="faqCard" onClick={() => handleFaqOpen(3)}>
            <div className="faqTitle pointer" onClick={() => setOpen(true)}>
              <div>1.3 Support Services</div>
              {numbers.includes(3) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#3B3B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              {!numbers.includes(3) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.75 4.5C11.9489 4.5 12.1397 4.57902 12.2803 4.71967C12.421 4.86032 12.5 5.05109 12.5 5.25V11H18.25C18.4489 11 18.6397 11.079 18.7803 11.2197C18.921 11.3603 19 11.5511 19 11.75C19 11.9489 18.921 12.1397 18.7803 12.2803C18.6397 12.421 18.4489 12.5 18.25 12.5H12.5V18.25C12.5 18.4489 12.421 18.6397 12.2803 18.7803C12.1397 18.921 11.9489 19 11.75 19C11.5511 19 11.3603 18.921 11.2197 18.7803C11.079 18.6397 11 18.4489 11 18.25V12.5H5.25C5.05109 12.5 4.86032 12.421 4.71967 12.2803C4.57902 12.1397 4.5 11.9489 4.5 11.75C4.5 11.5511 4.57902 11.3603 4.71967 11.2197C4.86032 11.079 5.05109 11 5.25 11H11V5.25C11 5.05109 11.079 4.86032 11.2197 4.71967C11.3603 4.57902 11.5511 4.5 11.75 4.5Z"
                    fill="#3B3B3B"
                  />
                </svg>
              )}
            </div>
            {numbers.includes(3) && (
              <div className="faqSubtitle">
                To respond to support requests and otherwise provide support for
                and resolve problems with the Services.
              </div>
            )}
          </div>
          <div className="faqCard">
            <div className="faqTitle pointer" onClick={() => handleFaqOpen(4)}>
              <div>1.4 Communications</div>
              {numbers.includes(4) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#3B3B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              {!numbers.includes(4) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.75 4.5C11.9489 4.5 12.1397 4.57902 12.2803 4.71967C12.421 4.86032 12.5 5.05109 12.5 5.25V11H18.25C18.4489 11 18.6397 11.079 18.7803 11.2197C18.921 11.3603 19 11.5511 19 11.75C19 11.9489 18.921 12.1397 18.7803 12.2803C18.6397 12.421 18.4489 12.5 18.25 12.5H12.5V18.25C12.5 18.4489 12.421 18.6397 12.2803 18.7803C12.1397 18.921 11.9489 19 11.75 19C11.5511 19 11.3603 18.921 11.2197 18.7803C11.079 18.6397 11 18.4489 11 18.25V12.5H5.25C5.05109 12.5 4.86032 12.421 4.71967 12.2803C4.57902 12.1397 4.5 11.9489 4.5 11.75C4.5 11.5511 4.57902 11.3603 4.71967 11.2197C4.86032 11.079 5.05109 11 5.25 11H11V5.25C11 5.05109 11.079 4.86032 11.2197 4.71967C11.3603 4.57902 11.5511 4.5 11.75 4.5Z"
                    fill="#3B3B3B"
                  />
                </svg>
              )}
            </div>
            {numbers.includes(4) && (
              <div className="faqSubtitle">
                To send service, technical, and administrative emails, messages,
                and other communications. Service-related communications about
                changes to the Services and important Services-related notices,
                such as maintenance and security announcements, are essential to
                delivery of the Services and you cannot opt-out of these
                communications. Marketing communications, if any, about new
                product features, offerings, and other news about CloseCarbon
                are optional; you have the choice whether or not to receive them
                and you may opt out by using the unsubscribe mechanism in such
                communications, or by changing the email preferences in your
                account.
              </div>
            )}
          </div>
          <div className="faqCard">
            <div className="faqTitle pointer" onClick={() => handleFaqOpen(5)}>
              <div>1.5 Account Management</div>
              {numbers.includes(5) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#3B3B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              {!numbers.includes(5) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.75 4.5C11.9489 4.5 12.1397 4.57902 12.2803 4.71967C12.421 4.86032 12.5 5.05109 12.5 5.25V11H18.25C18.4489 11 18.6397 11.079 18.7803 11.2197C18.921 11.3603 19 11.5511 19 11.75C19 11.9489 18.921 12.1397 18.7803 12.2803C18.6397 12.421 18.4489 12.5 18.25 12.5H12.5V18.25C12.5 18.4489 12.421 18.6397 12.2803 18.7803C12.1397 18.921 11.9489 19 11.75 19C11.5511 19 11.3603 18.921 11.2197 18.7803C11.079 18.6397 11 18.4489 11 18.25V12.5H5.25C5.05109 12.5 4.86032 12.421 4.71967 12.2803C4.57902 12.1397 4.5 11.9489 4.5 11.75C4.5 11.5511 4.57902 11.3603 4.71967 11.2197C4.86032 11.079 5.05109 11 5.25 11H11V5.25C11 5.05109 11.079 4.86032 11.2197 4.71967C11.3603 4.57902 11.5511 4.5 11.75 4.5Z"
                    fill="#3B3B3B"
                  />
                </svg>
              )}
            </div>
            {numbers.includes(5) && (
              <div className="faqSubtitle">
                To contact you in connection with account management, feedback,
                and other administrative matters related to your account with us
                and the Services.
              </div>
            )}
          </div>
          <div className="faqCard">
            <div className="faqTitle pointer" onClick={() => handleFaqOpen(6)}>
              <div>1.6 Security Purposes</div>
              {numbers.includes(6) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#3B3B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              {!numbers.includes(6) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.75 4.5C11.9489 4.5 12.1397 4.57902 12.2803 4.71967C12.421 4.86032 12.5 5.05109 12.5 5.25V11H18.25C18.4489 11 18.6397 11.079 18.7803 11.2197C18.921 11.3603 19 11.5511 19 11.75C19 11.9489 18.921 12.1397 18.7803 12.2803C18.6397 12.421 18.4489 12.5 18.25 12.5H12.5V18.25C12.5 18.4489 12.421 18.6397 12.2803 18.7803C12.1397 18.921 11.9489 19 11.75 19C11.5511 19 11.3603 18.921 11.2197 18.7803C11.079 18.6397 11 18.4489 11 18.25V12.5H5.25C5.05109 12.5 4.86032 12.421 4.71967 12.2803C4.57902 12.1397 4.5 11.9489 4.5 11.75C4.5 11.5511 4.57902 11.3603 4.71967 11.2197C4.86032 11.079 5.05109 11 5.25 11H11V5.25C11 5.05109 11.079 4.86032 11.2197 4.71967C11.3603 4.57902 11.5511 4.5 11.75 4.5Z"
                    fill="#3B3B3B"
                  />
                </svg>
              )}
            </div>
            {numbers.includes(6) && (
              <div className="faqSubtitle">
                To help prevent and investigate security issues and abuse.
              </div>
            )}
          </div>
          <div className="faqCard">
            <div className="faqTitle pointer" onClick={() => handleFaqOpen(7)}>
              <div>1.7 Legal Obligations</div>
              {numbers.includes(7) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#3B3B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              {!numbers.includes(7) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.75 4.5C11.9489 4.5 12.1397 4.57902 12.2803 4.71967C12.421 4.86032 12.5 5.05109 12.5 5.25V11H18.25C18.4489 11 18.6397 11.079 18.7803 11.2197C18.921 11.3603 19 11.5511 19 11.75C19 11.9489 18.921 12.1397 18.7803 12.2803C18.6397 12.421 18.4489 12.5 18.25 12.5H12.5V18.25C12.5 18.4489 12.421 18.6397 12.2803 18.7803C12.1397 18.921 11.9489 19 11.75 19C11.5511 19 11.3603 18.921 11.2197 18.7803C11.079 18.6397 11 18.4489 11 18.25V12.5H5.25C5.05109 12.5 4.86032 12.421 4.71967 12.2803C4.57902 12.1397 4.5 11.9489 4.5 11.75C4.5 11.5511 4.57902 11.3603 4.71967 11.2197C4.86032 11.079 5.05109 11 5.25 11H11V5.25C11 5.05109 11.079 4.86032 11.2197 4.71967C11.3603 4.57902 11.5511 4.5 11.75 4.5Z"
                    fill="#3B3B3B"
                  />
                </svg>
              )}
            </div>
            {numbers.includes(7) && (
              <div className="faqSubtitle">
                To comply with legal obligations as required by applicable law,
                legal process, or regulations as described above.
              </div>
            )}
          </div>
        </div>

        <div className="contentTitle">How we share and disclose data:</div>
        <div className="contentParagraph">
          CloseCarbon will not use or disclose your Personal Information for any
          purpose other than to the extent reasonably necessary to perform the
          Services and related support for the Services. As a matter of policy,
          absent your express consent, we do not sell or rent information about
          you, and we will not disclose information about you in a manner
          inconsistent with this Privacy Policy except as required by law or
          government regulation.
        </div>
        <div className="contentParagraph">
          We cooperate with law enforcement inquiries, to enforce laws such as
          those regarding intellectual property rights, fraud, and other
          personal rights. WE CAN (AND YOU AUTHORIZE US TO) DISCLOSE ANY
          INFORMATION ABOUT YOU TO LAW ENFORCEMENT, OTHER GOVERNMENT OFFICIALS
          OR ANY OTHER THIRD PARTY THAT WE, AT OUR SOLE DISCRETION, BELIEVE
          NECESSARY OR APPROPRIATE IN CONNECTION WITH AN INVESTIGATION OF FRAUD,
          INTELLECTUAL PROPERTY INFRINGEMENT OR OTHER ACTIVITY THAT IS ILLEGAL
          OR MAY EXPOSE US, OR YOU, TO LIABILITY.
        </div>
        <div className="contentFaq">
          <div className="faqCard">
            <div className="faqTitle pointer" onClick={() => handleFaqOpen(8)}>
              <div>2.1 With Your Consent</div>
              {numbers.includes(8) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#3B3B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              {!numbers.includes(8) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.75 4.5C11.9489 4.5 12.1397 4.57902 12.2803 4.71967C12.421 4.86032 12.5 5.05109 12.5 5.25V11H18.25C18.4489 11 18.6397 11.079 18.7803 11.2197C18.921 11.3603 19 11.5511 19 11.75C19 11.9489 18.921 12.1397 18.7803 12.2803C18.6397 12.421 18.4489 12.5 18.25 12.5H12.5V18.25C12.5 18.4489 12.421 18.6397 12.2803 18.7803C12.1397 18.921 11.9489 19 11.75 19C11.5511 19 11.3603 18.921 11.2197 18.7803C11.079 18.6397 11 18.4489 11 18.25V12.5H5.25C5.05109 12.5 4.86032 12.421 4.71967 12.2803C4.57902 12.1397 4.5 11.9489 4.5 11.75C4.5 11.5511 4.57902 11.3603 4.71967 11.2197C4.86032 11.079 5.05109 11 5.25 11H11V5.25C11 5.05109 11.079 4.86032 11.2197 4.71967C11.3603 4.57902 11.5511 4.5 11.75 4.5Z"
                    fill="#3B3B3B"
                  />
                </svg>
              )}
            </div>
            {numbers.includes(8) && (
              <div className="faqSubtitle">
                CloseCarbon may disclose your data, including Personal
                Information, to third parties when we have your express consent
                to do so. This includes any information that you post to your
                user page or otherwise on our Site and/or via the Services – by
                publishing such information via the Site or Services, you
                consent to Pachama’s disclosure of this information.
              </div>
            )}
          </div>
          <div className="faqCard">
            <div className="faqTitle pointer" onClick={() => handleFaqOpen(9)}>
              <div>2.2 Rendering the Services</div>
              {numbers.includes(9) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#3B3B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              {!numbers.includes(9) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.75 4.5C11.9489 4.5 12.1397 4.57902 12.2803 4.71967C12.421 4.86032 12.5 5.05109 12.5 5.25V11H18.25C18.4489 11 18.6397 11.079 18.7803 11.2197C18.921 11.3603 19 11.5511 19 11.75C19 11.9489 18.921 12.1397 18.7803 12.2803C18.6397 12.421 18.4489 12.5 18.25 12.5H12.5V18.25C12.5 18.4489 12.421 18.6397 12.2803 18.7803C12.1397 18.921 11.9489 19 11.75 19C11.5511 19 11.3603 18.921 11.2197 18.7803C11.079 18.6397 11 18.4489 11 18.25V12.5H5.25C5.05109 12.5 4.86032 12.421 4.71967 12.2803C4.57902 12.1397 4.5 11.9489 4.5 11.75C4.5 11.5511 4.57902 11.3603 4.71967 11.2197C4.86032 11.079 5.05109 11 5.25 11H11V5.25C11 5.05109 11.079 4.86032 11.2197 4.71967C11.3603 4.57902 11.5511 4.5 11.75 4.5Z"
                    fill="#3B3B3B"
                  />
                </svg>
              )}
            </div>
            {numbers.includes(9) && (
              <div className="faqSubtitle">
                CloseCarbon’s employees and contractors may have access to your
                data on a need to know and confidential basis to the extent
                necessary to render the Services and related support for the
                Services. We require such employees and contractors to treat
                your information consistent with this Privacy Policy.
              </div>
            )}
          </div>
          <div className="faqCard">
            <div className="faqTitle pointer" onClick={() => handleFaqOpen(10)}>
              <div>2.3 Affiliates</div>
              {numbers.includes(10) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#3B3B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              {!numbers.includes(10) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.75 4.5C11.9489 4.5 12.1397 4.57902 12.2803 4.71967C12.421 4.86032 12.5 5.05109 12.5 5.25V11H18.25C18.4489 11 18.6397 11.079 18.7803 11.2197C18.921 11.3603 19 11.5511 19 11.75C19 11.9489 18.921 12.1397 18.7803 12.2803C18.6397 12.421 18.4489 12.5 18.25 12.5H12.5V18.25C12.5 18.4489 12.421 18.6397 12.2803 18.7803C12.1397 18.921 11.9489 19 11.75 19C11.5511 19 11.3603 18.921 11.2197 18.7803C11.079 18.6397 11 18.4489 11 18.25V12.5H5.25C5.05109 12.5 4.86032 12.421 4.71967 12.2803C4.57902 12.1397 4.5 11.9489 4.5 11.75C4.5 11.5511 4.57902 11.3603 4.71967 11.2197C4.86032 11.079 5.05109 11 5.25 11H11V5.25C11 5.05109 11.079 4.86032 11.2197 4.71967C11.3603 4.57902 11.5511 4.5 11.75 4.5Z"
                    fill="#3B3B3B"
                  />
                </svg>
              )}
            </div>
            {numbers.includes(10) && (
              <div className="faqSubtitle">
                We may share your information with our affiliates, in which case
                we will require those affiliates to honour this Privacy Policy.
                Affiliates include our parent company and/or any subsidiaries
                (if any), companies with an ownership interest in CloseCarbon,
                joint venture partners, or other companies that we control or
                that are under common control with us.
              </div>
            )}
          </div>
          <div className="faqCard">
            <div className="faqTitle pointer" onClick={() => handleFaqOpen(11)}>
              <div>2.4 Third Party Providers</div>
              {numbers.includes(11) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#3B3B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              {!numbers.includes(11) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.75 4.5C11.9489 4.5 12.1397 4.57902 12.2803 4.71967C12.421 4.86032 12.5 5.05109 12.5 5.25V11H18.25C18.4489 11 18.6397 11.079 18.7803 11.2197C18.921 11.3603 19 11.5511 19 11.75C19 11.9489 18.921 12.1397 18.7803 12.2803C18.6397 12.421 18.4489 12.5 18.25 12.5H12.5V18.25C12.5 18.4489 12.421 18.6397 12.2803 18.7803C12.1397 18.921 11.9489 19 11.75 19C11.5511 19 11.3603 18.921 11.2197 18.7803C11.079 18.6397 11 18.4489 11 18.25V12.5H5.25C5.05109 12.5 4.86032 12.421 4.71967 12.2803C4.57902 12.1397 4.5 11.9489 4.5 11.75C4.5 11.5511 4.57902 11.3603 4.71967 11.2197C4.86032 11.079 5.05109 11 5.25 11H11V5.25C11 5.05109 11.079 4.86032 11.2197 4.71967C11.3603 4.57902 11.5511 4.5 11.75 4.5Z"
                    fill="#3B3B3B"
                  />
                </svg>
              )}
            </div>
            {numbers.includes(11) && (
              <div className="faqSubtitle">
                CloseCarbon engages third parties to process data in support of
                delivering of the Services (“Third Party Providers”). We may
                share your data, including Personal Information, with Third
                Party Service Providers (e.g., email services, platform hosting,
                cloud computing services, data storage and processing
                facilities) to the limited extent necessary to let them perform
                business functions and services for us or on our behalf in
                connection with the provision of the Services.
              </div>
            )}
          </div>
          <div className="faqCard">
            <div className="faqTitle pointer" onClick={() => handleFaqOpen(12)}>
              <div>2.5 Third Party Services</div>
              {numbers.includes(12) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#3B3B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              {!numbers.includes(12) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.75 4.5C11.9489 4.5 12.1397 4.57902 12.2803 4.71967C12.421 4.86032 12.5 5.05109 12.5 5.25V11H18.25C18.4489 11 18.6397 11.079 18.7803 11.2197C18.921 11.3603 19 11.5511 19 11.75C19 11.9489 18.921 12.1397 18.7803 12.2803C18.6397 12.421 18.4489 12.5 18.25 12.5H12.5V18.25C12.5 18.4489 12.421 18.6397 12.2803 18.7803C12.1397 18.921 11.9489 19 11.75 19C11.5511 19 11.3603 18.921 11.2197 18.7803C11.079 18.6397 11 18.4489 11 18.25V12.5H5.25C5.05109 12.5 4.86032 12.421 4.71967 12.2803C4.57902 12.1397 4.5 11.9489 4.5 11.75C4.5 11.5511 4.57902 11.3603 4.71967 11.2197C4.86032 11.079 5.05109 11 5.25 11H11V5.25C11 5.05109 11.079 4.86032 11.2197 4.71967C11.3603 4.57902 11.5511 4.5 11.75 4.5Z"
                    fill="#3B3B3B"
                  />
                </svg>
              )}
            </div>
            {numbers.includes(12) && (
              <div className="faqSubtitle">
                You may enable or permit integrations with or use of Third-Party
                Services in connection with the Services. When enabled,
                CloseCarbon may share certain data with such Third-Party
                Services as requested to effectuate the integration, including
                data regarding your credentials related to the Services. As
                mentioned above, Third Party Services are not owned or
                controlled by CloseCarbon and third parties that have been
                granted access to your data may have their own policies and
                practices for its collection and use; you should check the
                privacy settings and notices of these Third-Party Services to
                understand their privacy practices.
              </div>
            )}
          </div>
          <div className="faqCard">
            <div className="faqTitle pointer" onClick={() => handleFaqOpen(13)}>
              <div>2.6 Enforcement of Agreements</div>
              {numbers.includes(13) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#3B3B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              {!numbers.includes(13) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.75 4.5C11.9489 4.5 12.1397 4.57902 12.2803 4.71967C12.421 4.86032 12.5 5.05109 12.5 5.25V11H18.25C18.4489 11 18.6397 11.079 18.7803 11.2197C18.921 11.3603 19 11.5511 19 11.75C19 11.9489 18.921 12.1397 18.7803 12.2803C18.6397 12.421 18.4489 12.5 18.25 12.5H12.5V18.25C12.5 18.4489 12.421 18.6397 12.2803 18.7803C12.1397 18.921 11.9489 19 11.75 19C11.5511 19 11.3603 18.921 11.2197 18.7803C11.079 18.6397 11 18.4489 11 18.25V12.5H5.25C5.05109 12.5 4.86032 12.421 4.71967 12.2803C4.57902 12.1397 4.5 11.9489 4.5 11.75C4.5 11.5511 4.57902 11.3603 4.71967 11.2197C4.86032 11.079 5.05109 11 5.25 11H11V5.25C11 5.05109 11.079 4.86032 11.2197 4.71967C11.3603 4.57902 11.5511 4.5 11.75 4.5Z"
                    fill="#3B3B3B"
                  />
                </svg>
              )}
            </div>
            {numbers.includes(13) && (
              <div className="faqSubtitle">
                CloseCarbon may disclose data to ensure compliance with and to
                enforce contractual or legal obligations with respect to the
                Services and our business, including any applicable lease
                agreements.
              </div>
            )}
          </div>
          <div className="faqCard">
            <div className="faqTitle pointer" onClick={() => handleFaqOpen(14)}>
              <div>2.7 Protection of Rights</div>
              {numbers.includes(14) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#3B3B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              {!numbers.includes(14) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.75 4.5C11.9489 4.5 12.1397 4.57902 12.2803 4.71967C12.421 4.86032 12.5 5.05109 12.5 5.25V11H18.25C18.4489 11 18.6397 11.079 18.7803 11.2197C18.921 11.3603 19 11.5511 19 11.75C19 11.9489 18.921 12.1397 18.7803 12.2803C18.6397 12.421 18.4489 12.5 18.25 12.5H12.5V18.25C12.5 18.4489 12.421 18.6397 12.2803 18.7803C12.1397 18.921 11.9489 19 11.75 19C11.5511 19 11.3603 18.921 11.2197 18.7803C11.079 18.6397 11 18.4489 11 18.25V12.5H5.25C5.05109 12.5 4.86032 12.421 4.71967 12.2803C4.57902 12.1397 4.5 11.9489 4.5 11.75C4.5 11.5511 4.57902 11.3603 4.71967 11.2197C4.86032 11.079 5.05109 11 5.25 11H11V5.25C11 5.05109 11.079 4.86032 11.2197 4.71967C11.3603 4.57902 11.5511 4.5 11.75 4.5Z"
                    fill="#3B3B3B"
                  />
                </svg>
              )}
            </div>
            {numbers.includes(14) && (
              <div className="faqSubtitle">
                CloseCarbon may disclose data to protect and defend our rights
                and property, including intellectual property rights, and to
                ensure compliance with applicable laws and enforce third party
                rights, including intellectual property and privacy rights.
              </div>
            )}
          </div>
          <div className="faqCard">
            <div className="faqTitle pointer" onClick={() => handleFaqOpen(15)}>
              <div>2.8 Safety and Security</div>
              {numbers.includes(15) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#3B3B3B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              {!numbers.includes(15) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.75 4.5C11.9489 4.5 12.1397 4.57902 12.2803 4.71967C12.421 4.86032 12.5 5.05109 12.5 5.25V11H18.25C18.4489 11 18.6397 11.079 18.7803 11.2197C18.921 11.3603 19 11.5511 19 11.75C19 11.9489 18.921 12.1397 18.7803 12.2803C18.6397 12.421 18.4489 12.5 18.25 12.5H12.5V18.25C12.5 18.4489 12.421 18.6397 12.2803 18.7803C12.1397 18.921 11.9489 19 11.75 19C11.5511 19 11.3603 18.921 11.2197 18.7803C11.079 18.6397 11 18.4489 11 18.25V12.5H5.25C5.05109 12.5 4.86032 12.421 4.71967 12.2803C4.57902 12.1397 4.5 11.9489 4.5 11.75C4.5 11.5511 4.57902 11.3603 4.71967 11.2197C4.86032 11.079 5.05109 11 5.25 11H11V5.25C11 5.05109 11.079 4.86032 11.2197 4.71967C11.3603 4.57902 11.5511 4.5 11.75 4.5Z"
                    fill="#3B3B3B"
                  />
                </svg>
              )}
            </div>
            {numbers.includes(15) && (
              <div className="faqSubtitle">
                CloseCarbon may disclose data to protect your safety and
                security; to protect the safety, security, and property of our
                users; and to protect the safety, security, and property of
                CloseCarbon and our employees, agents, representatives, and
                contractors.
              </div>
            )}
          </div>
        </div>

        <div className="contentTitle">Security Measures</div>
        <div className="contentParagraph">
          CloseCarbon maintains physical, technical, and administrative
          procedures to protect the data we collect and to secure it from
          improper or unauthorized use. We work hard to protect data in our
          custody and control from loss, misuse, and unauthorized access, use,
          disclosure, modification, or destruction, and to use industry-standard
          security measures in order to ensure an appropriate level of security
          in light of reasonably available methods in relation to the risks and
          nature of the information we collect.
        </div>
        <div className="contentTitle">International Data Transfers</div>
        <div className="contentParagraph">
          CloseCarbon primarily processes and stores data in connection with the
          Services in Nigeria. However, it is possible that data may be
          processed other countries by Third-Party. We will take measures to
          ensure that your Personal Information remains protected to the
          standards described in this Privacy Policy and any such transfers
          comply with applicable data protection laws. In certain circumstances,
          courts, law enforcement agencies, regulatory agencies or security
          authorities in those other countries may be entitled to access your
          Personal Information. collect.
        </div>
        <div className="contentTitle">Enforcement</div>
        <div className="contentParagraph">
          CloseCarbon will actively monitor its relevant privacy and security
          practices to verify adherence to this Privacy Policy. Any agents,
          contractors, service providers, or other third parties subject to this
          Privacy Policy that CloseCarbon determines to be in violation of this
          Privacy Policy or applicable data protection laws will be subject to
          disciplinary action up to and including termination of applicable
          services. Kindly contact us immediately at the contact information
          provided under the “Contact Us” heading above if you believe there has
          been a material violation of this Privacy Policy.
        </div>
        <div className="contentTitle">Changes to this Privacy Policy</div>
        <div className="contentParagraph">
          We reserve the right make changes to this Privacy Policy from time to
          time, in whole or in part, in our sole discretion, at any time without
          prior notice by posting an updated version of this Privacy Policy on
          the Site or when you access the Services. When we do, we will revise
          the “last updated” date at the beginning of the Privacy Policy. We
          will make commercially reasonable efforts to notify you by email,
          through the Services (at log-in or otherwise), and/or by posting a
          prominent notice on our Site that our Privacy Policy has been updated.
          Any changes, modifications, or updates to this Privacy Policy will
          become effective immediately upon posting. By continuing to use the
          Services, you agree to be bound changes we make to this Privacy
          Policy. If you disagree with the changes to this Privacy Policy, your
          only remedy is to discontinue use of the Services and deactivate your
          account.
        </div> */}
      </div>
      <Footer />
    </div>
  );
}
