import { useState } from "react";
import SideBar from "../../components/ThirdPartyDashboard/SideBar";
import "../../styles/ThirdParty/Verification.scss";
import { useEffect } from "react";
import Search from "../../components/ThirdPartyDashboard/Search";
import {
  getPlantsData as greenBasicGetAllEmissionData,
  getStaticAttributes as greenBasicGetStaticAttributes,
} from "../../instantiateTransaction/basicGreenProject";
import {
  getPlantsData as infraBasicGetAllEmissionData,
  getStaticAttributes as infraBasicGetStaticAttributes,
} from "../../instantiateTransaction/basicInfrastructureChange";
import {
  getPlantsData as greenGovernanceGetAllEmissionData,
  getStaticAttributes as greenGovernanceGetStaticAttributes,
} from "../../instantiateTransaction/governanceGreenProject";
import {
  getPlantsData as infraGovernanceGetAllEmissionData,
  getStaticAttributes as infraGovernanceGetStaticAttributes,
} from "../../instantiateTransaction/gorvernanceInfrastructureChange";
import {
  getPlantsData as governanceMonitoringGetAllEmissionData,
  getStaticAttributes as governanceMonitoringGetStaticAttributes,
} from "../../instantiateTransaction/governanceCarbonMonitoring";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { getDatabase, ref, onValue } from "firebase/database";
import moment from "moment";
import { facilityVerify } from "../../apis/General/web3/shared";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Illustration from "../../images/9264828.jpg";
import { getKyc } from "../../apis/General/users";
import { Tab, Tabs } from "@mui/material";
import Kyc from "../../components/ThirdPartyDashboard/Kyc";


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const Verification = () => {
  const auth = useSelector((state) => state.auth);
  console.log("auth", auth);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenTwo, setModalOpenTwo] = useState(false);
  const [totalEmission, setTotalEmission] = useState(0);
  const [open, setOpen] = useState(0);
  const [loading, setLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);
 
  const [selectedFacility, setSelectedFacility] = useState([]);
  const [activities, setActivities] = useState([]);
  const [selectedKyc, setSelectedKyc] = useState("");
  const [currentIndex, setCurrentIndex] = useState(null);
  const [selectedStaticAttributes, setSelectedStaticAttributes] =
    useState(null);
  
    const [value, setValue] = useState(0);


    const [greenBasicFacilitiesVerified, setGreenBasicFacilitiesVerified] = useState([]);
  const [greenBasicFacilitiesUnverified, setGreenBasicFacilitiesUnverified] = useState([]);
  const [infraBasicFacilitiesVerified, setInfraBasicFacilitiesVerified] = useState([]);
  const [infraBasicFacilitiesUnverified, setInfraBasicFacilitiesUnverified] = useState([]);
  const [greenGovernanceFacilitiesVerified, setGreenGovernanceFacilitiesVerified] = useState([]);
  const [greenGovernanceFacilitiesUnverified, setGreenGovernanceFacilitiesUnverified] = useState([]);
  const [infraGovernanceFacilitiesVerified, setInfraGovernanceFacilitiesVerified] = useState([]);
  const [infraGovernanceFacilitiesUnverified, setInfraGovernanceFacilitiesUnverified] = useState([]);
  const [monitoringVerified, setMonitoringVerified] = useState([]);
  const [monitoringUnverified, setMonitoringUnverified] = useState([]);
  const [verifiedLength, setVerifiedLength] = useState(0);
  const [unVerifiedLength, setUnVerifiedLength] = useState(0);


    const handleTabChange = (event, newValue) => {
      setValue(newValue);
    };
  
    
  const getValidationData = async () => {
    setGeneralLoading(true);
    try {
      const response6 = await greenBasicGetAllEmissionData(0, 20);
      console.log("response6", response6);

      const temp1 = response6?.getAllInfo?.filter((item) => !!item?.isVerified)
      const temp2 = response6?.getAllInfo?.filter((item) => !item?.isVerified)
      setGreenBasicFacilitiesVerified(temp1);
      setGreenBasicFacilitiesUnverified(temp2);

      const response7 = await infraBasicGetAllEmissionData(0, 20);
      console.log("response7", response7);

      const temp3 = response7?.getAllInfo?.filter((item) => !!item?.isVerified)
      const temp4 = response7?.getAllInfo?.filter((item) => !item?.isVerified)

      setInfraBasicFacilitiesVerified(temp3);
      setInfraBasicFacilitiesUnverified(temp4);


      const response8 = await greenGovernanceGetAllEmissionData(0, 20);

      const temp5 = response8?.getAllInfo?.filter((item) => !!item?.isVerified)
      const temp6 = response8?.getAllInfo?.filter((item) => !item?.isVerified)

      setGreenGovernanceFacilitiesVerified(temp5);
      setGreenGovernanceFacilitiesUnverified(temp6);


      const response9 = await infraGovernanceGetAllEmissionData(0, 20);

      const temp7 = response9?.getAllInfo?.filter((item) => !!item?.isVerified)
      const temp8 = response9?.getAllInfo?.filter((item) => !item?.isVerified)

      setInfraGovernanceFacilitiesVerified(temp7);
      setInfraGovernanceFacilitiesUnverified(temp8);

      const response10 = await governanceMonitoringGetAllEmissionData(0, 20);

      const temp9 = response10?.getAllInfo?.filter((item) => !!item?.isVerified)
      const temp10 = response10?.getAllInfo?.filter((item) => !item?.isVerified)

      setMonitoringVerified(temp9);
      setMonitoringUnverified(temp10);


      setVerifiedLength(
        temp1?.length + 
        temp3?.length + 
        temp5?.length + 
        temp7?.length + 
        temp9?.length 
      )

      setUnVerifiedLength(
        temp2?.length + 
        temp4?.length + 
        temp6?.length + 
        temp8?.length + 
        temp10?.length 
      )
    } catch (error) {
      console.log("ERROR", error);
      // toast.error("Something went wrong");
    }
    setGeneralLoading(false);
  };

  const getActivities = async () => {
    // setGeneralLoading(true);
    const database = getDatabase();

    const newRef = ref(database, "events");
    await onValue(newRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setActivities(Object.values(data));
      }
      // setGeneralLoading(false);
    });
  };

  useEffect(() => {
    getValidationData();
    getActivities();
  }, []);

  const getLastActivity = (address) => {
    const filteredData = activities?.filter(
      (item) =>
        item?.addressToRecord === address && item?.type === "Plant Verified"
    );
    const lastData = filteredData[filteredData.length - 1];
    if (filteredData?.length > 0) {
      return `${lastData?.type}, by ${lastData?.addressToRecord} on ${moment(
        parseInt(lastData?.timestamp) * 1000
      ).format("Do MMM YYYY")} at ${moment(
        parseInt(lastData?.timestamp) * 1000
      ).format("hh:mm A")}`;
    } else {
      return "No Verification has been carried out for this facility";
    }
  };

  const handleVerification = async (facility) => {
    setLoading(true);
    let response;
    try {
      // if (!facility?.isValidated) {
      //   alert("Validation has not been carried out for this Facility's data");
      //   setModalOpen(false);
      // } else if (facility?.isVerified) {
      //   alert(
      //     "Verification has already been carried out for this Facility's data"
      //   );
      //   setModalOpen(false);
      // } else if (facility?.isRejected) {
      //   alert("This Facility's data failed Validation");
      //   setModalOpen(false);
      // } 
       if (
        facility?.scenario?.toLowerCase() === "green" ||
        facility?.scenario?.toLowerCase() === "green-project"
      ) {
        response = await facilityVerify(
          "green",
          "basic",
          facility?.plantAddress,
          auth?.token
        );
      }
       else if (
        facility?.scenario === "infrastructure-change" 
        // &&
        // facility?.subscriptionPlan === "basic"
      ) {
        response = await facilityVerify(
          "infrastructure-change",
          "basic",
          facility?.plantAddress,
          auth?.token
        );
      }
      //   if (
      //   facility?.scenario?.toLowerCase() === "green" ||
      //   facility?.scenario?.toLowerCase() === "green-project"
      //   // &&
      //   // facility?.subscriptionPlan === "governance"
      // ) {
      //   response = await facilityVerify(
      //     "green",
      //     "governance",
      //     facility?.plantAddress,
      //     auth?.token
      //   );
      // } else if (
      //   facility?.scenario === "infrastructure-change" 
      //   // &&
      //   // facility?.subscriptionPlan === "governance"
      // ) {
      //   response = await facilityVerify(
      //     "infrastructure-change",
      //     "governance",
      //     facility?.plantAddress,
      //     auth?.token
      //   );
      // } 
      else if (facility?.scenario === "carbon-monitoring") {
        response = await facilityVerify(
          "carbon-monitoring",
          "governance",
          facility?.plantAddress,
          auth?.token
        );
      }
      else {
        alert("Invalid Scenario");
        setLoading(false);
        return;
      }
      console.log('verification response', response);

      if (response?.data?.statusCode === 200) {
        toast.success("Verification Successful");
        setModalOpen(false);
        setModalOpenTwo(false);
        getValidationData();
      } else if (response?.data?.errorMessage) {
        toast.error(response?.data?.errorMessage);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.log("ERROR", error);
      // toast.error("Something went wrong");
    }
    setLoading(false);
  };

  const handleModalOpen = async (facility, index) => {
    setCurrentIndex(index);
    setLoading(true);
    try {
      console.log("facility", facility, facility?.scenario, facility?.scenario === "infrastructure-change");
      setSelectedFacility(facility);
      let response;
      if (
        facility?.scenario === "infrastructure-change"
        //  &&
        // facility?.subscriptionPlan === "basic"
      ) {
        response = await infraBasicGetStaticAttributes(facility?.plantAddress);
      } else if (
        facility?.scenario === "green"
        // &&
        // facility?.subscriptionPlan === "basic"
      ) {
        response = await greenBasicGetStaticAttributes(facility?.plantAddress);
      }
      // if (
      //   facility?.scenario === "infrastructure-change" 
      //   // &&
      //   // facility?.subscriptionPlan === "governance"
      // ) {
      //   response = await infraGovernanceGetStaticAttributes(facility?.plantAddress);
      // } else if (
      //   facility?.scenario === "green" 
      //   // &&
      //   // facility?.subscriptionPlan === "governance"
      // ) {
      //   response = await greenGovernanceGetStaticAttributes(facility?.plantAddress);
      // }
      else if (facility?.scenario === "carbon-monitoring") {
        response = await governanceMonitoringGetStaticAttributes(
          facility?.plantAddress
        );
      } 
      else {
        alert("Invalid Scenario");
      }
      console.log("response static", response);
      const response2 = await getKyc(
        facility?.scenario === "green"
          ? response?.staticAttributes[5]
          : response?.staticAttributes[6],
        auth?.token
      );
      console.log("response2", response2);
      setSelectedStaticAttributes(response?.staticAttributes);
      setSelectedKyc(response2?.data?.message);
      // setSelectedId(
      //   facility?.scenario === "green"
      //     ? response?.staticAttributes[5]
      //     : response?.staticAttributes[6]
      // );
      setModalOpen(true);
    } catch (error) {
      console.log("ERROR", error);
      toast.error("Something went wrong");
    }
    setLoading(false);
  };

  const handleModalClose = (e) => {
    if (e.target.classList.contains("modal") || e.target.classList.contains("validation-modal")) {
      setModalOpen(false);
      setModalOpenTwo(false);
    }
  };

  return (
    <div className="thirdparty_verification">
      <div className="sidebar_container">
        <SideBar />
      </div>

      <div className="main">
        <Search />
        <Tabs
  value={value}
  onChange={handleTabChange}
  aria-label="project tabs"
  className="tab_label_container"
>
  <Tab
    className={value === 0 ? "tab_label tab_active" : "tab_label"}
    label={
      <div className="tab-label-content">
        <span>Unverified Projects</span>
        {unVerifiedLength > 0 && <span className="tab-label-content__count">{unVerifiedLength}</span>}
      </div>
    }
    {...a11yProps(0)}
  />
  <Tab
    className={value === 1 ? "tab_label tab_active" : "tab_label"}
    label={
      <div className="tab-label-content">
        <span>Verified Projects</span>
        {verifiedLength > 0 && <span className="tab-label-content__count">{verifiedLength}</span>}
      </div>
    }
    {...a11yProps(1)}
  />
</Tabs>
        {!generalLoading &&
        value === 1 && 
        greenBasicFacilitiesVerified?.length === 0 &&
        infraBasicFacilitiesVerified?.length === 0 &&
        greenGovernanceFacilitiesVerified?.length === 0 &&
        infraGovernanceFacilitiesVerified?.length === 0 &&
        monitoringVerified?.length === 0 && (
            <div className="empty_list">
              <img src={Illustration} alt="" />
              <div>No verification data here yet</div>
            </div>
          )}
        {!generalLoading &&
        value === 0 && 
        greenBasicFacilitiesUnverified?.length === 0 &&
        infraBasicFacilitiesUnverified?.length === 0 &&
        greenGovernanceFacilitiesUnverified?.length === 0 &&
        infraGovernanceFacilitiesUnverified?.length === 0 &&
        monitoringUnverified?.length === 0 && (
            <div className="empty_list">
              <img src={Illustration} alt="" />
              <div>No verification data here yet</div>
            </div>
          )}

        {/* {greenFacilities?.length > 0 && (
          <div className="category">Green Project</div>
        )} */}
        {value === 1 && greenBasicFacilitiesVerified?.map((facility, index) => (
          <div className="verification_card">
            <div className="title_group">
              <div className="card_title">
                Organisation Name - {facility?.organizationName}
              </div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setOpen(open === index ? null : index)}
                className={open === index ? "svg_closed" : ""}
              >
                <circle
                  cx="12"
                  cy="12"
                  r="12"
                  transform="matrix(1 0 0 -1 0 24)"
                  fill="#D0D5DD"
                />
                <path
                  d="M8.66683 13.3333L12.0002 10L15.3335 13.3333"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            {open === index && (
              <div>
                <div className="card_divider"></div>

                <div className="verification_grid">
                  <div>
                    <div>CO2 tracked:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.totalGHGEmission.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Baseline:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.quantifiedGHGEmissionsInBaselineScenario.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Energy Consumed:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.energyConsumptionInKWh?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Reporting period:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        facility?.emissionData?.reportingPeriod?.toString()}
                    </div>
                  </div>
                </div>

                <div className="confirm">
                  <div>
                    {/* <button>Reject</button> */}
                    <button onClick={() => handleModalOpen(facility, index)}>
                      {loading && index === currentIndex
                        ? "Loading..."
                        : "Verify"}
                    </button>
                  </div>
                </div>

                <div className="log">
                  <span>Last Activity:</span>{" "}
                  {getLastActivity(facility?.plantAddress)}
                </div>
              </div>
            )}
          </div>
        ))}
        {value === 0 && greenBasicFacilitiesUnverified?.map((facility, index) => (
          <div className="verification_card">
            <div className="title_group">
              <div className="card_title">
                Organisation Name - {facility?.organizationName}
              </div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setOpen(open === index ? null : index)}
                className={open === index ? "svg_closed" : ""}
              >
                <circle
                  cx="12"
                  cy="12"
                  r="12"
                  transform="matrix(1 0 0 -1 0 24)"
                  fill="#D0D5DD"
                />
                <path
                  d="M8.66683 13.3333L12.0002 10L15.3335 13.3333"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            {open === index && (
              <div>
                <div className="card_divider"></div>

                <div className="verification_grid">
                  <div>
                    <div>CO2 tracked:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.totalGHGEmission.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Baseline:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.quantifiedGHGEmissionsInBaselineScenario.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Energy Consumed:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.energyConsumptionInKWh?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Reporting period:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        facility?.emissionData?.reportingPeriod?.toString()}
                    </div>
                  </div>
                </div>

                <div className="confirm">
                  <div>
                    {/* <button>Reject</button> */}
                    <button onClick={() => handleModalOpen(facility, index)}>
                      {loading && index === currentIndex
                        ? "Loading..."
                        : "Verify"}
                    </button>
                  </div>
                </div>

                <div className="log">
                  <span>Last Activity:</span>{" "}
                  {getLastActivity(facility?.plantAddress)}
                </div>
              </div>
            )}
          </div>
        ))}
        {value === 1 && greenGovernanceFacilitiesVerified?.map((facility, index) => (
          <div className="verification_card">
            <div className="title_group">
              <div className="card_title">
                Organisation Name - {facility?.organizationName}
              </div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setOpen(open === index ? null : index)}
                className={open === index ? "svg_closed" : ""}
              >
                <circle
                  cx="12"
                  cy="12"
                  r="12"
                  transform="matrix(1 0 0 -1 0 24)"
                  fill="#D0D5DD"
                />
                <path
                  d="M8.66683 13.3333L12.0002 10L15.3335 13.3333"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            {open === index && (
              <div>
                <div className="card_divider"></div>

                <div className="verification_grid">
                  <div>
                    <div>CO2 tracked:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.totalGHGEmission.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Baseline:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.quantifiedGHGEmissionsInBaselineScenario.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Energy Consumed:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.energyConsumptionInKWh?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Reporting period:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        facility?.emissionData?.reportingPeriod?.toString()}
                    </div>
                  </div>
                </div>

                <div className="confirm">
                  <div>
                    {/* <button>Reject</button> */}
                    <button onClick={() => handleModalOpen(facility, index)}>
                      {loading && index === currentIndex
                        ? "Loading..."
                        : "Verify"}
                    </button>
                  </div>
                </div>

                <div className="log">
                  <span>Last Activity:</span>{" "}
                  {getLastActivity(facility?.plantAddress)}
                </div>
              </div>
            )}
          </div>
        ))}
        {value === 0 && greenGovernanceFacilitiesUnverified?.map((facility, index) => (
          <div className="verification_card">
            <div className="title_group">
              <div className="card_title">
                Organisation Name - {facility?.organizationName}
              </div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setOpen(open === index ? null : index)}
                className={open === index ? "svg_closed" : ""}
              >
                <circle
                  cx="12"
                  cy="12"
                  r="12"
                  transform="matrix(1 0 0 -1 0 24)"
                  fill="#D0D5DD"
                />
                <path
                  d="M8.66683 13.3333L12.0002 10L15.3335 13.3333"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            {open === index && (
              <div>
                <div className="card_divider"></div>

                <div className="verification_grid">
                  <div>
                    <div>CO2 tracked:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.totalGHGEmission.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Baseline:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.quantifiedGHGEmissionsInBaselineScenario.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Energy Consumed:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.energyConsumptionInKWh?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Reporting period:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        facility?.emissionData?.reportingPeriod?.toString()}
                    </div>
                  </div>
                </div>

                <div className="confirm">
                  <div>
                    {/* <button>Reject</button> */}
                    <button onClick={() => handleModalOpen(facility, index)}>
                      {loading && index === currentIndex
                        ? "Loading..."
                        : "Verify"}
                    </button>
                  </div>
                </div>

                <div className="log">
                  <span>Last Activity:</span>{" "}
                  {getLastActivity(facility?.plantAddress)}
                </div>
              </div>
            )}
          </div>
        ))}
        {/* {infrastructureChange?.length > 0 && (
          <div className="category">Infrastructure Change</div>
        )} */}

        {value === 1 && infraBasicFacilitiesVerified?.map((facility, index) => (
          <div className="verification_card">
            <div className="title_group">
              <div className="card_title">
                Organisation Name - {facility?.organizationName}
              </div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setOpen(open === index ? null : index)}
                className={open === index ? "svg_closed" : ""}
              >
                <circle
                  cx="12"
                  cy="12"
                  r="12"
                  transform="matrix(1 0 0 -1 0 24)"
                  fill="#D0D5DD"
                />
                <path
                  d="M8.66683 13.3333L12.0002 10L15.3335 13.3333"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            {open === index && (
              <div>
                <div className="card_divider"></div>

                <div className="verification_grid">
                  <div>
                    <div>CO2 tracked:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.totalGHGEmissions?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Baseline:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.quantifiedGHGEmissionsInBaselineScenario?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Direct Stationary Emission::</div>
                    {/* Change this to fuel consumed */}
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.directStationaryEmissionsValue?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Reporting period:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        facility?.emissionData?.reportingPeriod?.toString()}
                    </div>
                  </div>
                </div>

                <div className="confirm">
                  <div>
                    {/* <button>Reject</button> */}
                    <button onClick={() => handleModalOpen(facility, index)}>
                      {loading && index === currentIndex
                        ? "Loading..."
                        : "Verify"}
                    </button>
                  </div>
                </div>

                <div className="log">
                  <span>Last Activity:</span>{" "}
                  {getLastActivity(facility?.plantAddress)}
                </div>
              </div>
            )}
          </div>
        ))}
        {value === 0 && infraBasicFacilitiesUnverified?.map((facility, index) => (
          <div className="verification_card">
            <div className="title_group">
              <div className="card_title">
                Organisation Name - {facility?.organizationName}
              </div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setOpen(open === index ? null : index)}
                className={open === index ? "svg_closed" : ""}
              >
                <circle
                  cx="12"
                  cy="12"
                  r="12"
                  transform="matrix(1 0 0 -1 0 24)"
                  fill="#D0D5DD"
                />
                <path
                  d="M8.66683 13.3333L12.0002 10L15.3335 13.3333"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            {open === index && (
              <div>
                <div className="card_divider"></div>

                <div className="verification_grid">
                  <div>
                    <div>CO2 tracked:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.totalGHGEmissions?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Baseline:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.quantifiedGHGEmissionsInBaselineScenario?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Direct Stationary Emission:</div>
                    {/* Change this to fuel consumed */}
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.directStationaryEmissionsValue?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Reporting period:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        facility?.emissionData?.reportingPeriod?.toString()}
                    </div>
                  </div>
                </div>

                <div className="confirm">
                  <div>
                    {/* <button>Reject</button> */}
                    <button onClick={() => handleModalOpen(facility, index)}>
                      {loading && index === currentIndex
                        ? "Loading..."
                        : "Verify"}
                    </button>
                  </div>
                </div>

                <div className="log">
                  <span>Last Activity:</span>{" "}
                  {getLastActivity(facility?.plantAddress)}
                </div>
              </div>
            )}
          </div>
        ))}
        {value === 1 && infraGovernanceFacilitiesVerified?.map((facility, index) => (
          <div className="verification_card">
            <div className="title_group">
              <div className="card_title">
                Organisation Name - {facility?.organizationName}
              </div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setOpen(open === index ? null : index)}
                className={open === index ? "svg_closed" : ""}
              >
                <circle
                  cx="12"
                  cy="12"
                  r="12"
                  transform="matrix(1 0 0 -1 0 24)"
                  fill="#D0D5DD"
                />
                <path
                  d="M8.66683 13.3333L12.0002 10L15.3335 13.3333"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            {open === index && (
              <div>
                <div className="card_divider"></div>

                <div className="verification_grid">
                  <div>
                    <div>CO2 tracked:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.totalGHGEmissions?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Baseline:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.quantifiedGHGEmissionsInBaselineScenario?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Direct Stationary Emission::</div>
                    {/* Change this to fuel consumed */}
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.directStationaryEmissionsValue?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Reporting period:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        facility?.emissionData?.reportingPeriod?.toString()}
                      
                    </div>
                  </div>
                </div>

                <div className="confirm">
                  <div>
                    {/* <button>Reject</button> */}
                    <button onClick={() => handleModalOpen(facility, index)}>
                      {loading && index === currentIndex
                        ? "Loading..."
                        : "Verify"}
                    </button>
                  </div>
                </div>

                <div className="log">
                  <span>Last Activity:</span>{" "}
                  {getLastActivity(facility?.plantAddress)}
                </div>
              </div>
            )}
          </div>
        ))}
        {value === 0 && infraGovernanceFacilitiesUnverified?.map((facility, index) => (
          <div className="verification_card">
            <div className="title_group">
              <div className="card_title">
                Organisation Name - {facility?.organizationName}
              </div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setOpen(open === index ? null : index)}
                className={open === index ? "svg_closed" : ""}
              >
                <circle
                  cx="12"
                  cy="12"
                  r="12"
                  transform="matrix(1 0 0 -1 0 24)"
                  fill="#D0D5DD"
                />
                <path
                  d="M8.66683 13.3333L12.0002 10L15.3335 13.3333"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            {open === index && (
              <div>
                <div className="card_divider"></div>

                <div className="verification_grid">
                  <div>
                    <div>CO2 tracked:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.totalGHGEmissions?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Baseline:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.quantifiedGHGEmissionsInBaselineScenario?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Direct Stationary Emission::</div>
                    {/* Change this to fuel consumed */}
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.directStationaryEmissionsValue?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Reporting period:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        facility?.emissionData?.reportingPeriod?.toString()}
                    </div>
                  </div>
                </div>

                <div className="confirm">
                  <div>
                    {/* <button>Reject</button> */}
                    <button onClick={() => handleModalOpen(facility, index)}>
                      {loading && index === currentIndex
                        ? "Loading..."
                        : "Verify"}
                    </button>
                  </div>
                </div>

                <div className="log">
                  <span>Last Activity:</span>{" "}
                  {getLastActivity(facility?.plantAddress)}
                </div>
              </div>
            )}
          </div>
        ))}
        {value === 1 && monitoringVerified?.map((facility, index) => (
          <div className="verification_card">
            <div className="title_group">
              <div className="card_title">
                Organisation Name - {facility?.organizationName}
              </div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setOpen(open === index ? null : index)}
                className={open === index ? "svg_closed" : ""}
              >
                <circle
                  cx="12"
                  cy="12"
                  r="12"
                  transform="matrix(1 0 0 -1 0 24)"
                  fill="#D0D5DD"
                />
                <path
                  d="M8.66683 13.3333L12.0002 10L15.3335 13.3333"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            {open === index && (
              <div>
                <div className="card_divider"></div>

                <div className="verification_grid">
                  <div>
                    <div>CO2 tracked:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.totalGHGEmissions?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Baseline:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.quantifiedGHGEmissionsInBaselineScenario?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Direct Stationary Emission::</div>
                    {/* Change this to fuel consumed */}
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.directStationaryEmissionsValue?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Reporting period:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        facility?.emissionData?.reportingPeriod?.toString()}
                    </div>
                  </div>
                </div>

                <div className="confirm">
                  <div>
                    {/* <button>Reject</button> */}
                    <button onClick={() => handleModalOpen(facility, index)}>
                      {loading && index === currentIndex
                        ? "Loading..."
                        : "Verify"}
                    </button>
                  </div>
                </div>

                <div className="log">
                  <span>Last Activity:</span>{" "}
                  {getLastActivity(facility?.plantAddress)}
                </div>
              </div>
            )}
          </div>
        ))}
        {value === 0 && monitoringUnverified?.map((facility, index) => (
          <div className="verification_card">
            <div className="title_group">
              <div className="card_title">
                Organisation Name - {facility?.organizationName}
              </div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setOpen(open === index ? null : index)}
                className={open === index ? "svg_closed" : ""}
              >
                <circle
                  cx="12"
                  cy="12"
                  r="12"
                  transform="matrix(1 0 0 -1 0 24)"
                  fill="#D0D5DD"
                />
                <path
                  d="M8.66683 13.3333L12.0002 10L15.3335 13.3333"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            {open === index && (
              <div>
                <div className="card_divider"></div>

                <div className="verification_grid">
                  <div>
                    <div>CO2 tracked:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.totalGHGEmissions?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Baseline:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.quantifiedGHGEmissionsInBaselineScenario?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Direct Stationary Emission::</div>
                    {/* Change this to fuel consumed */}
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        ethers.formatEther(
                          facility?.emissionData?.directStationaryEmissionsValue?.toString()
                        )}
                      tCO2e
                    </div>
                  </div>
                  <div>
                    <div>Reporting period:</div>
                    <div>
                      {facility?.emissionData?.length > 0 &&
                        facility?.emissionData?.reportingPeriod?.toString()}
                    </div>
                  </div>
                </div>

                <div className="confirm">
                  <div>
                    {/* <button>Reject</button> */}
                    <button onClick={() => handleModalOpen(facility, index)}>
                      {loading && index === currentIndex
                        ? "Loading..."
                        : "Verify"}
                    </button>
                  </div>
                </div>

                <div className="log">
                  <span>Last Activity:</span>{" "}
                  {getLastActivity(facility?.plantAddress)}
                </div>
              </div>
            )}
          </div>
        ))}
        {/* {monitoring?.length > 0 && (
          <div className="category">Carbon Monitoring</div>
        )} */}

        {generalLoading && (
          <>
            <div className="category">
              <Skeleton
                width={120}
                height={20}
                borderRadius={8}
                style={{ marginBottom: "8px" }}
              />
            </div>

            <div className="verification_card">
              <div className="title_group">
                <div className="card_title">
                  <Skeleton
                    width={240}
                    height={20}
                    borderRadius={8}
                    style={{ marginBottom: "8px" }}
                  />
                </div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setOpen(open === 0 ? null : 0)}
                  className={open === 0 ? "svg_closed" : ""}
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="12"
                    transform="matrix(1 0 0 -1 0 24)"
                    fill="#D0D5DD"
                  />
                  <path
                    d="M8.66683 13.3333L12.0002 10L15.3335 13.3333"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              {open === 0 && (
                <div>
                  <div className="card_divider"></div>

                  <div className="verification_grid">
                    <div>
                      <div>
                        <Skeleton
                          width={120}
                          height={20}
                          borderRadius={8}
                          style={{ marginBottom: "8px" }}
                        />
                      </div>
                      <div>
                        <Skeleton
                          width={240}
                          height={20}
                          borderRadius={8}
                          style={{ marginBottom: "8px" }}
                        />
                      </div>
                    </div>
                    {/* <div>
                <div>CO2 Offset:</div>
                <div>620CO2e</div>
              </div> */}
                  </div>

                  <div className="log">
                    <Skeleton
                      width={120}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                    <Skeleton
                      width="60%"
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {modalOpen && (
          <div className="modal" onClick={handleModalClose}>
            <div className="modal_inner">
              <div className="title">Confirmation of Compliance</div>
              <div className="subtitle">
                By proceeding, you affirm that all data and information meet the
                required standard, and all procedures are standard throughout
                the process.
              </div>

              <button
                onClick={() => {
                  setModalOpenTwo(true)
                  setModalOpen(false)
                }}
                disabled={loading}
              >
                {loading ? "Loading..." : "Confirm"}
              </button>
            </div>
          </div>
        )}

{modalOpenTwo && (
          <div className="validation-modal" onClick={handleModalClose}>
          <div className="validation-modal_inner">
            <div className="title">{selectedFacility?.organizationName}</div>
            {/* <div className="subtitle">
              Plot 42B Fola agoro, Satelite Ogudu, Lagos Nigeria
            </div> */}

<div className="modal_grid">
                <div className="grid_item">
                  <div className="label">
                    {selectedFacility?.scenario === "green"
                      ? "Green project description"
                      : "Facility/Project description"}
                  </div>
                  <div className="document">
                    {selectedFacility?.scenario === "green" ? selectedKyc?.greenProjectDescription : selectedKyc?.facilityDescription}
                  </div>
                </div>
                <div className="grid_item">
                  <div className="label">
                    {selectedFacility?.scenario === "green"
                      ? "Green project location"
                      : "Facility/Project location"}
                  </div>
                  <div className="document">
                    {selectedFacility?.scenario === "green" ? selectedKyc?.greenProjectLocation : selectedKyc?.facilityLocation}
                  </div>
                </div>
                {selectedFacility?.scenario !== "carbon-monitoring" && <div className="grid_item">
                  <div className="label">
                    {selectedFacility?.scenario === "green"
                      ? "Green project/ Renewable energy"
                      : "Facility/Project fuel source"}
                  </div>
                  <div className="document">
                    {selectedFacility?.scenario === "green"
                      ? selectedKyc?.renewableEnergySolutions
                      : selectedKyc?.projectFuelSource}
                  </div>
                </div>}
              {selectedFacility?.scenario !== "carbon-monitoring" &&  <div className="grid_item">
                  <div className="label">Baseline scenario</div>
                  <div className="document">
                    {selectedKyc?.baseLineScenario}
                  </div>
                </div>}
                {selectedFacility?.scenario !== "carbon-monitoring" &&<div className="grid_item">
                  <div className="label">
                    {selectedFacility?.scenario === "green"
                      ? "Energy source in baseline scenario"
                      : "Fuel source in baseline scenario"}
                  </div>
                  <div className="document">
                    {selectedFacility?.scenario === "green"
                      ? selectedKyc?.baseLineEnergySource
                      : selectedKyc?.baseLineFuelSource}
                  </div>
                </div>}
                <div className="grid_item pointer">
                  <div className="label">Quantification Methodology</div>
                  <div
                    className="document"
                    onClick={() => window.open(`https://${selectedKyc?.quantificationMethodology}`)}
                  >
                    <div>Uploaded Document</div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 1.5C6.70435 1.5 5.94129 1.81607 5.37868 2.37868C4.81607 2.94129 4.5 3.70435 4.5 4.5V19.5C4.5 20.2956 4.81607 21.0587 5.37868 21.6213C5.94129 22.1839 6.70435 22.5 7.5 22.5H16.5C17.2956 22.5 18.0587 22.1839 18.6213 21.6213C19.1839 21.0587 19.5 20.2956 19.5 19.5V8.121C19.4995 7.52446 19.2621 6.95255 18.84 6.531L14.4705 2.1585C14.2615 1.9496 14.0133 1.78393 13.7403 1.67094C13.4672 1.55795 13.1745 1.49987 12.879 1.5H7.5ZM6 4.5C6 4.10218 6.15804 3.72064 6.43934 3.43934C6.72064 3.15804 7.10218 3 7.5 3H12V6.75C12 7.34674 12.2371 7.91903 12.659 8.34099C13.081 8.76295 13.6533 9 14.25 9H18V19.5C18 19.8978 17.842 20.2794 17.5607 20.5607C17.2794 20.842 16.8978 21 16.5 21H7.5C7.10218 21 6.72064 20.842 6.43934 20.5607C6.15804 20.2794 6 19.8978 6 19.5V4.5ZM17.6895 7.5H14.25C14.0511 7.5 13.8603 7.42098 13.7197 7.28033C13.579 7.13968 13.5 6.94891 13.5 6.75V3.3105L17.6895 7.5Z"
                        fill="#667185"
                      />
                    </svg>
                  </div>
                </div>
                {selectedFacility?.scenario !== "carbon-monitoring" &&<div className="grid_item">
                  <div className="label">
                    Additional information relevant to the project
                  </div>
                  <div className="document">
                    {selectedKyc?.additionalInfomation}
                  </div>
                </div>}
              </div>

            <div className="button_group">
                {/* <button
                  // onClick={() => setModalOpen2(true)}
                >
                  Reject
                </button> */}
                <button
                  onClick={() => handleVerification(selectedFacility)}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Verify"}
                </button>
              </div>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default Verification;
