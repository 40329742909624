import { setDeviceHasMotionSupport } from 'components/ReduxAppWrapper/store/actions/globals'
import { useCallback, useEffect } from 'preact/compat'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import usePerformanceBenchmark from '~core/Benchmark/usePerformanceBenchmark'
import useDeviceCapabilities from '~core/DeviceCapabilities/useDeviceCapabilities'
import useSdkConfigurationService from '~core/SdkConfiguration/useSdkConfigurationService'
import { GlobalActions } from '~types/redux'
import { StepConfig } from '~types/steps'

type Props = {
  deviceHasCameraSupport?: boolean
}

const useMotion = ({ deviceHasCameraSupport }: Props = {}) => {
  const { experimental_features, motion_capture } = useSdkConfigurationService()
  const dispatch = useDispatch<Dispatch<GlobalActions>>()

  const motionABTestEnabled =
    experimental_features?.motion_experiment?.enabled ?? false
  const devicePassedBenchmark = usePerformanceBenchmark()
  const deviceCapabilities = useDeviceCapabilities()
  let deviceCanUseMotion: boolean | undefined
  let deviceCantUseMotionReason:
    | 'no_camera_support'
    | 'no_media_recorder'
    | 'unsupported_browser'
    | 'benchmark_failed'
    | 'no_wasm_support'
    | undefined

  if (devicePassedBenchmark !== undefined) {
    const isBenchmarkPassed = devicePassedBenchmark !== false
    const isWasmSupported = deviceCapabilities?.wasm_support === true
    const isMotionSupported = motion_capture?.supported === true

    deviceCanUseMotion =
      isBenchmarkPassed && isWasmSupported && isMotionSupported

    if (!deviceHasCameraSupport) {
      deviceCantUseMotionReason = 'no_camera_support'
    } else if (window.MediaRecorder == null) {
      deviceCantUseMotionReason = 'no_media_recorder'
    } else if (!isWasmSupported) {
      deviceCantUseMotionReason = 'no_wasm_support'
    } else if (!isMotionSupported) {
      deviceCantUseMotionReason = 'unsupported_browser'
    } else if (!isBenchmarkPassed) {
      deviceCantUseMotionReason = 'benchmark_failed'
    }
  }

  useEffect(() => {
    dispatch(setDeviceHasMotionSupport(!!deviceCanUseMotion))
  }, [deviceCanUseMotion, dispatch])

  const replaceFaceWithActiveVideoStep = useCallback(
    (steps: StepConfig[]) => {
      if (!deviceCanUseMotion || !motionABTestEnabled) {
        return steps
      }

      const faceIndex = steps.findIndex(({ type }) => type === 'face')
      const activeVideoIndex = steps.findIndex(
        ({ type }) => type === 'activeVideo'
      )

      if (faceIndex < 0 || activeVideoIndex >= 0) {
        return steps
      }

      const activeVideo: StepConfig = {
        type: 'activeVideo',
        //@ts-ignore
        original: steps[faceIndex],
      }

      return [
        ...steps.slice(0, faceIndex),
        activeVideo,
        ...steps.slice(faceIndex + 1),
      ]
    },
    [deviceCanUseMotion, motionABTestEnabled]
  )

  return {
    replaceFaceWithActiveVideoStep,
    deviceCanUseMotion,
    deviceCantUseMotionReason,
  }
}

export default useMotion
