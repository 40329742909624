import "../../styles/ThirdParty/Signup.scss";
import {
  Box,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  TextField,
  Input,
  Autocomplete,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import {
  registerEmail,
  resendOtp,
  signUp,
  verifyEmail,
} from "../../apis/ThirdPartyDashboard/authentication";
import { useDispatch } from "react-redux";
import { countries } from "../../data/countries";
import { toast } from "react-toastify";
import { getUserInfo } from "../../apis/General/users";


const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathname = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [stage, setStage] = useState(2);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [country, setCountry] = useState("country");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inst1, setInst1] = useState(false);
  const [inst2, setInst2] = useState(false);
  const [inst3, setInst3] = useState(false);
  const [inst4, setInst4] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [errorButtonAction, setErrorButtonAction] = useState("");
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [role, setRole] = useState("");
  const [verifierType, setVerifierType] = useState("");


  
  const fetchUserInfo = async () => {
    setLoading(true);
    const response = await getUserInfo(pathname?.id);
    console.log('response getUserInfo', response);
    setEmail(response?.data?.message?.email)
    setFirstName(response?.data?.message?.firstName)
    setLastName(response?.data?.message?.lastName)
    setPhone(response?.data?.message?.phoneNumber)
    setVerifierType(response?.data?.message?.verifierType)
    setLoading(false);
  }


  useEffect(() => {
    fetchUserInfo();
  }, [])

  // useEffect(() => {
  //  if(pathname?.id && pathname?.role === "CCV") {
  //   setStage(2)
  //   setRole("thirdParty")
  //  } 
  //  else if(pathname?.id && pathname?.role === "SUB-CCV") {
  //   setStage(2)
  //   setRole("verifiers")
  //  } 
  // }, [pathname])


  useEffect(() => {
    const otpInputs = document.querySelectorAll(".otp_input");

    otpInputs.forEach((input, index) => {
      input.addEventListener("input", (event) => {
        const inputValue = event.target.value;

        if (inputValue && index < otpInputs.length - 1) {
          otpInputs[index + 1].focus();
        }
      });

      input.addEventListener("keydown", (event) => {
        if (event.key === "Backspace" && index > 0 && !input.value) {
          otpInputs[index - 1].focus();
          event.preventDefault();
        }
      });
    });
  }, [stage === 2]);

  const passwordRegex1 = /^.{8,}/;
  const passwordRegex2 = /(?=.*[a-z])(?=.*[A-Z])/;
  const passwordRegex3 = /(?=.*\d)/;
  const passwordRegex4 = /(?=.*[!@#$%^&*])/;
  useEffect(() => {
    if (passwordRegex1.test(password)) {
      setInst1(true);
    } else {
      setInst1(false);
    }
    if (passwordRegex2.test(password)) {
      setInst2(true);
    } else {
      setInst2(false);
    }
    if (passwordRegex3.test(password)) {
      setInst3(true);
    } else {
      setInst3(false);
    }
    if (passwordRegex4.test(password)) {
      setInst4(true);
    } else {
      setInst4(false);
    }
  }, [password]);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleErrorModalOpen = () => {
    setErrorModalOpen(true);
  };

  const handleModalClose = (e) => {
    if (e.target.classList.contains("modal")) {
      setModalOpen(false);
    }
  };

  const handleErrorModalClose = (e) => {
    if (e.target.classList.contains("errorModal")) {
      setErrorModalOpen(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleForgotPassword = () => {
    setStage(1);
    handleModalOpen();
  };
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await registerEmail(email, role);
    console.log('response', response);
    if (response?.data?.statusCode === 200) {
      if (
        response?.data?.message ===
        "This email has been registered. Please input the otp sent to your email to activate or click resend OTP to get a new OTP"
      ) {
        // alert(
        //   "This email has been registered. Please input the otp sent to your email to activate or request for a new OTP"
        // );
        handleShowError("Oops email already existed", "This email already exists. Kindly click the button below to input the otp sent to your email to activate or request for a new OTP");
      }
      setStage(2);
    } else if (
      response?.data?.message ===
      "This email has been registered. Complete the sign up process"
    ) {
     // alert("This email has been registered. Complete the sign up process");
     handleShowError("Oops email already registered", "It appears this email has already been registered.  Kindly click the button below to complete the sign up process");
     setStage(3);
    } else if (response?.data?.statusCode === 400) {
      handleShowError("Oops email already registered", "It appears this email has already been registered. If you’ve forgotten your password, click “Forgot Password” on the login screen.", "login");
    }
    setLoading(false);
  };

  
  const handleShowError = (title, body, action="ok") => {
    setErrorTitle(title)
    setErrorText(body)
    setErrorButtonAction(action)
    handleErrorModalOpen();
   };

  const clearOtpInput = () => {
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    setOtp5("");
    setOtp6("");
  };
  const handleResendEmail = async (e) => {
    setLoading(true);
    e.preventDefault();
    const response = await resendOtp(email);
    if (response?.data?.statusCode === 200) {
      setModalOpen(false);
      clearOtpInput();
      setOtpError(false);
    } 

    else if (
      response?.data?.message ===
      "Email verified! Please complete your sign-up."
    ) {
      toast.error(response?.data?.message);

      setModalOpen(false);
      clearOtpInput();
      setOtpError(false);
      setStage(3)
    }
    
    else {
      toast.error(response?.data?.message);
    }
    setLoading(false);
  };
  const handleOTPSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const otp = `${otp1}${otp2}${otp3}${otp4}${otp5}${otp6}`;
    const response = await verifyEmail(otp, email);
    console.log('response900', response);
    if (response?.data?.statusCode === 200) {
      setStage(3);
    }else if (response?.data?.message === "Token used. Please, proceed to sign up") {
      alert(response?.data?.message)
      setStage(3);
    } else if (response?.data?.statusCode === 401) {
      // handleShowError("OTP expired", "The one-time code you entered has expired. Request a new OTP to complete your registration.", "Request OTP");
      setOtpError(true)
    }
    setLoading(false);
  };

  const handleDetailsSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
   setStage(4)
    setLoading(false);
  };
  const handleSignup = async (e) => {
    setLoading(true);
    e.preventDefault();

    //Regex for a 8 characters long password that contains at least one upper and lower case letter, a number and a symbol
    let re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (password !== confirmPassword) {
      // alert("Passwords do not match");
      handleShowError("Passwords do not match", "The passwords you entered do not match. Please check and try again");
    } else if (password === confirmPassword && !re.test(confirmPassword)) {
      // alert(
      //   "Your password must be at least 8 characters long and contain at least one upper and lower case letter, a number and a symbol"
      // );
      handleShowError("Passwords does not meet requirement", "Your password must be at least 8 characters long and contain at least one upper and lower case letter, a number and a symbol");
    } else {
      const response = await signUp(
        firstName,
        lastName,
        organization,
        email,
        idNumber,
        country,
        address,
        phone,
        password,
        "verifier",
        countryCode,
        verifierType
      );

      console.log('signUp', response);

      if (response?.data?.statusCode === 200) {
        localStorage.setItem("token", response?.data?.message);

        dispatch({
          type: "REGISTER_SUCCESS",
          payload: {
            token: response?.data?.message,
          },
        });
        navigate("/third-party/verification");
        window.scrollTo(0, 0);
      } else {
        toast.error(response?.data?.message);
      }
    }

    setLoading(false);
  };

  const handleCountryChange = (e, v) => {
    e.preventDefault();

    setLoading(true);
    setCountry(v?.label);
    setLoading(false);
  };

  return (
    <div className="signup">
      <div className="thirdparty_signup_inner">
        <div className="title">Redefining Carbon Emission Solutions...</div>

        <div className="thirdparty_box">
          {stage === 1 && (
            <>
              <div className="signup_link">
                Already have an account?{" "}
                <span
                  className="pointer"
                  onClick={() => {
                    navigate("/third-party/login");
                    window.scrollTo(0, 0);
                  }}
                >
                  Login
                </span>
              </div>
              <div className="box_title">Sign Up</div>

              <form onSubmit={handleEmailSubmit}>
                <Input
                  className="thirdparty_signup_input"
                  type="text"
                  placeholder="First name"
                  name="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <Input
                  className="thirdparty_signup_input"
                  type="text"
                  placeholder="Last name"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
                <Input
                  className="thirdparty_signup_input"
                  type="text"
                  placeholder="Phone number"
                  name="phoneNumber"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
                <Input
                  className="thirdparty_signup_input"
                  type="text"
                  placeholder="Email Address"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                {error && (
                  <div className="signup_error_text">
                    Email taken. Please input another one
                  </div>
                )}

                <button
                  className="signup_submit"
                  type="submit"
                  disabled={loading}
                >
                  <div>{loading ? "Loading..." : "Continue"}</div>
                </button>
              </form>
            </>
          )}
          {stage === 2 && (
            <form onSubmit={handleOTPSubmit}>
              <div className="box_title_verify box_title">
                Verify your email address
              </div>
              <div className="box_subtitle_verify box_subtitle">
                We sent a 5-digit-code to your email address.
              </div>
              <div className="box_subtitle_verify box_subtitle bold">
                Kindly input the code below.
              </div>
              <div className="otp_box_group">
                <div className="otp_box">
                  <input
                    className="otp_input"
                    type="text"
                    maxlength="1"
                    required
                    value={otp1}
                    name="otp1"
                    onChange={(e) => {
                      setOtp1(e.target.value);
                      setOtpError(false);
                    }}
                  />
                </div>

                <div className="otp_box">
                  <input
                    className="otp_input"
                    type="text"
                    maxlength="1"
                    required
                    value={otp2}
                    name="otp2"
                    onChange={(e) => {
                      setOtp2(e.target.value);
                      setOtpError(false);
                    }}
                  />
                </div>
                <div className="otp_box">
                  <input
                    className="otp_input"
                    type="text"
                    maxlength="1"
                    required
                    value={otp3}
                    name="otp3"
                    onChange={(e) => {
                      setOtp3(e.target.value);
                      setOtpError(false);
                    }}
                  />
                </div>
                <div className="otp_box">
                  <input
                    className="otp_input"
                    type="text"
                    maxlength="1"
                    required
                    value={otp4}
                    name="otp4"
                    onChange={(e) => {
                      setOtp4(e.target.value);
                      setOtpError(false);
                    }}
                  />
                </div>
                <div className="otp_box">
                  <input
                    className="otp_input"
                    type="text"
                    maxlength="1"
                    required
                    value={otp5}
                    name="otp5"
                    onChange={(e) => {
                      setOtp5(e.target.value);
                      setOtpError(false);
                    }}
                  />
                </div>
                <div className="otp_box">
                  <input
                    className="otp_input"
                    type="text"
                    maxlength="1"
                    required
                    value={otp6}
                    name="otp6"
                    onChange={(e) => {
                      setOtp6(e.target.value);
                      setOtpError(false);
                    }}
                  />
                </div>
              </div>
              {otpError && (
                <div className="otp_error_text">Invalid or Expired OTP</div>
              )}

              <button
                className="signup_submit"
                type="submit"
                disabled={loading}
              >
                <div>{loading ? "Loading..." : "Verify"}</div>
                {/* <svg
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5459 10.7959L8.79594 14.5459C8.58459 14.7573 8.29795 14.876 7.99906 14.876C7.70018 14.876 7.41353 14.7573 7.20219 14.5459C6.99084 14.3346 6.87211 14.0479 6.87211 13.7491C6.87211 13.4502 6.99084 13.1635 7.20219 12.9522L9.03125 11.125H1.25C0.951631 11.125 0.665483 11.0065 0.454505 10.7955C0.243526 10.5845 0.125 10.2984 0.125 10C0.125 9.70163 0.243526 9.41548 0.454505 9.2045C0.665483 8.99353 0.951631 8.875 1.25 8.875H9.03125L7.20406 7.04594C7.09942 6.94129 7.0164 6.81706 6.95977 6.68033C6.90314 6.5436 6.87399 6.39706 6.87399 6.24906C6.87399 5.95018 6.99272 5.66353 7.20406 5.45219C7.41541 5.24084 7.70205 5.12211 8.00094 5.12211C8.29982 5.12211 8.58647 5.24084 8.79781 5.45219L12.5478 9.20219C12.6526 9.30683 12.7356 9.43112 12.7923 9.56793C12.8489 9.70474 12.8779 9.85139 12.8778 9.99945C12.8776 10.1475 12.8482 10.2941 12.7912 10.4308C12.7343 10.5675 12.6509 10.6915 12.5459 10.7959ZM17 0.625H11.75C11.4516 0.625 11.1655 0.743526 10.9545 0.954505C10.7435 1.16548 10.625 1.45163 10.625 1.75C10.625 2.04837 10.7435 2.33452 10.9545 2.5455C11.1655 2.75647 11.4516 2.875 11.75 2.875H16.625V17.125H11.75C11.4516 17.125 11.1655 17.2435 10.9545 17.4545C10.7435 17.6655 10.625 17.9516 10.625 18.25C10.625 18.5484 10.7435 18.8345 10.9545 19.0455C11.1655 19.2565 11.4516 19.375 11.75 19.375H17C17.4973 19.375 17.9742 19.1775 18.3258 18.8258C18.6775 18.4742 18.875 17.9973 18.875 17.5V2.5C18.875 2.00272 18.6775 1.52581 18.3258 1.17417C17.9742 0.822544 17.4973 0.625 17 0.625Z"
                  fill="white"
                />
              </svg> */}
              </button>
              <div
                className=" didnt_get pointer center"
                onClick={handleModalOpen}
              >
                No, I didn’t get the code {" "}{" "} <span className="error">Resend code</span>
              </div>

              <div className="didnt_get2">
              <span className="error">Note:</span> Please check your <span className="didnt_get2__span">Spam filter</span> or <span className="didnt_get2__span">Junk mail</span> folder if you don’t receive an email us.
              </div>
            </form>
          )}

          {stage === 3 && (
            <>
              <div className="signup_link">
                Already have an account?{" "}
                <span
                  className="pointer"
                  onClick={() => {
                    navigate("/third-party/login");
                    window.scrollTo(0, 0);
                  }}
                >
                  Login
                </span>
              </div>
              <div className="box_title">Sign Up</div>

              <form onSubmit={handleDetailsSubmit}>
                <Input
                  className="thirdparty_signup_input"
                  type="text"
                  placeholder="Organisation you work with"
                  value={organization}
                  name="organisation"
                  onChange={(e) => setOrganization(e.target.value)}
                  required
                />
                <Input
                  type="text"
                  className="thirdparty_signup_input"
                  placeholder="ID Number"
                  value={idNumber}
                  name="idNumber"
                  onChange={(e) => setIdNumber(e.target.value)}
                  required
                />
                <Autocomplete
                  id="country-select-demo"
                  className="third_party_select"
                  fullWidth
                  placeholder="Nigeria"
                  sx={{ mb: "32px", marginTop: "-20px" }}
                  options={countries}
                  onChange={handleCountryChange}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Country"
                      className="third_party_select"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      variant="standard"
                    />
                  )}
                />

                <Input
                  className="thirdparty_signup_input"
                  type="text"
                  placeholder="Your Address"
                  value={address}
                  name="address"
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
                {/* <Input
                id="standard-adornment-password"
                className="thirdparty_signup_input"
                type="text"
                placeholder="Your Phone number"
              /> */}
               

                <button
                  className="signup_submit"
                  type="submit"
                  disabled={loading}
                >
                  <div>{loading ? "Loading..." : "Sign Up"}</div>
                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.5459 10.7959L8.79594 14.5459C8.58459 14.7573 8.29795 14.876 7.99906 14.876C7.70018 14.876 7.41353 14.7573 7.20219 14.5459C6.99084 14.3346 6.87211 14.0479 6.87211 13.7491C6.87211 13.4502 6.99084 13.1635 7.20219 12.9522L9.03125 11.125H1.25C0.951631 11.125 0.665483 11.0065 0.454505 10.7955C0.243526 10.5845 0.125 10.2984 0.125 10C0.125 9.70163 0.243526 9.41548 0.454505 9.2045C0.665483 8.99353 0.951631 8.875 1.25 8.875H9.03125L7.20406 7.04594C7.09942 6.94129 7.0164 6.81706 6.95977 6.68033C6.90314 6.5436 6.87399 6.39706 6.87399 6.24906C6.87399 5.95018 6.99272 5.66353 7.20406 5.45219C7.41541 5.24084 7.70205 5.12211 8.00094 5.12211C8.29982 5.12211 8.58647 5.24084 8.79781 5.45219L12.5478 9.20219C12.6526 9.30683 12.7356 9.43112 12.7923 9.56793C12.8489 9.70474 12.8779 9.85139 12.8778 9.99945C12.8776 10.1475 12.8482 10.2941 12.7912 10.4308C12.7343 10.5675 12.6509 10.6915 12.5459 10.7959ZM17 0.625H11.75C11.4516 0.625 11.1655 0.743526 10.9545 0.954505C10.7435 1.16548 10.625 1.45163 10.625 1.75C10.625 2.04837 10.7435 2.33452 10.9545 2.5455C11.1655 2.75647 11.4516 2.875 11.75 2.875H16.625V17.125H11.75C11.4516 17.125 11.1655 17.2435 10.9545 17.4545C10.7435 17.6655 10.625 17.9516 10.625 18.25C10.625 18.5484 10.7435 18.8345 10.9545 19.0455C11.1655 19.2565 11.4516 19.375 11.75 19.375H17C17.4973 19.375 17.9742 19.1775 18.3258 18.8258C18.6775 18.4742 18.875 17.9973 18.875 17.5V2.5C18.875 2.00272 18.6775 1.52581 18.3258 1.17417C17.9742 0.822544 17.4973 0.625 17 0.625Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </form>
            </>
          )}
          {stage === 4 && (
            <>
              <div className="signup_link">
                Already have an account?{" "}
                <span
                  className="pointer"
                  onClick={() => {
                    navigate("/third-party/login");
                    window.scrollTo(0, 0);
                  }}
                >
                  Login
                </span>
              </div>
              <div className="box_title">Sign Up</div>

              <form onSubmit={handleSignup}>
                <Input
                  className="thirdparty_signup_input"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  value={password}
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                

                <Input
                  id="standard-adornment-password"
                  className="thirdparty_signup_input"
                  type={showPassword ? "text" : "password"}
                  placeholder="Confirm password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  value={confirmPassword}
                  name="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />

<div className="instructions_title">Password must:</div>
                <div
                  className={
                    inst1 ? "instructions isFilled" : "instructions notFilled"
                  }
                >
                  {!inst1 && (
                    <span>
                      <svg
                        viewBox="0 0 1024 1024"
                        class="icon"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#000000"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          <path
                            d="M512 128C300.8 128 128 300.8 128 512s172.8 384 384 384 384-172.8 384-384S723.2 128 512 128z m0 85.333333c66.133333 0 128 23.466667 179.2 59.733334L273.066667 691.2C236.8 640 213.333333 578.133333 213.333333 512c0-164.266667 134.4-298.666667 298.666667-298.666667z m0 597.333334c-66.133333 0-128-23.466667-179.2-59.733334l418.133333-418.133333C787.2 384 810.666667 445.866667 810.666667 512c0 164.266667-134.4 298.666667-298.666667 298.666667z"
                            fill="#D50000"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  )}{" "}
                  {inst1 && (
                    <span>
                      <svg
                        viewBox="0 0 117 117"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        fill="#000000"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <title></title> <desc></desc> <defs></defs>{" "}
                          <g
                            fill="none"
                            fill-rule="evenodd"
                            id="Page-1"
                            stroke="none"
                            stroke-width="1"
                          >
                            {" "}
                            <g fill-rule="nonzero" id="correct">
                              {" "}
                              <path
                                d="M34.5,55.1 C32.9,53.5 30.3,53.5 28.7,55.1 C27.1,56.7 27.1,59.3 28.7,60.9 L47.6,79.8 C48.4,80.6 49.4,81 50.5,81 C50.6,81 50.6,81 50.7,81 C51.8,80.9 52.9,80.4 53.7,79.5 L101,22.8 C102.4,21.1 102.2,18.5 100.5,17 C98.8,15.6 96.2,15.8 94.7,17.5 L50.2,70.8 L34.5,55.1 Z"
                                fill="#17AB13"
                                id="Shape"
                              ></path>{" "}
                              <path
                                d="M89.1,9.3 C66.1,-5.1 36.6,-1.7 17.4,17.5 C-5.2,40.1 -5.2,77 17.4,99.6 C28.7,110.9 43.6,116.6 58.4,116.6 C73.2,116.6 88.1,110.9 99.4,99.6 C118.7,80.3 122,50.7 107.5,27.7 C106.3,25.8 103.8,25.2 101.9,26.4 C100,27.6 99.4,30.1 100.6,32 C113.1,51.8 110.2,77.2 93.6,93.8 C74.2,113.2 42.5,113.2 23.1,93.8 C3.7,74.4 3.7,42.7 23.1,23.3 C39.7,6.8 65,3.9 84.8,16.2 C86.7,17.4 89.2,16.8 90.4,14.9 C91.6,13 91,10.5 89.1,9.3 Z"
                                fill="#17AB13"
                                id="Shape"
                              ></path>{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </span>
                  )}
                  Password must be at least 8 characters long{" "}
                </div>
                <div
                  className={
                    inst2 ? "instructions isFilled" : "instructions notFilled"
                  }
                >
                  {!inst2 && (
                    <span>
                      <svg
                        viewBox="0 0 1024 1024"
                        class="icon"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#000000"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          <path
                            d="M512 128C300.8 128 128 300.8 128 512s172.8 384 384 384 384-172.8 384-384S723.2 128 512 128z m0 85.333333c66.133333 0 128 23.466667 179.2 59.733334L273.066667 691.2C236.8 640 213.333333 578.133333 213.333333 512c0-164.266667 134.4-298.666667 298.666667-298.666667z m0 597.333334c-66.133333 0-128-23.466667-179.2-59.733334l418.133333-418.133333C787.2 384 810.666667 445.866667 810.666667 512c0 164.266667-134.4 298.666667-298.666667 298.666667z"
                            fill="#D50000"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  )}
                  {inst2 && (
                    <span>
                      <svg
                        viewBox="0 0 117 117"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        fill="#000000"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <title></title> <desc></desc> <defs></defs>{" "}
                          <g
                            fill="none"
                            fill-rule="evenodd"
                            id="Page-1"
                            stroke="none"
                            stroke-width="1"
                          >
                            {" "}
                            <g fill-rule="nonzero" id="correct">
                              {" "}
                              <path
                                d="M34.5,55.1 C32.9,53.5 30.3,53.5 28.7,55.1 C27.1,56.7 27.1,59.3 28.7,60.9 L47.6,79.8 C48.4,80.6 49.4,81 50.5,81 C50.6,81 50.6,81 50.7,81 C51.8,80.9 52.9,80.4 53.7,79.5 L101,22.8 C102.4,21.1 102.2,18.5 100.5,17 C98.8,15.6 96.2,15.8 94.7,17.5 L50.2,70.8 L34.5,55.1 Z"
                                fill="#17AB13"
                                id="Shape"
                              ></path>{" "}
                              <path
                                d="M89.1,9.3 C66.1,-5.1 36.6,-1.7 17.4,17.5 C-5.2,40.1 -5.2,77 17.4,99.6 C28.7,110.9 43.6,116.6 58.4,116.6 C73.2,116.6 88.1,110.9 99.4,99.6 C118.7,80.3 122,50.7 107.5,27.7 C106.3,25.8 103.8,25.2 101.9,26.4 C100,27.6 99.4,30.1 100.6,32 C113.1,51.8 110.2,77.2 93.6,93.8 C74.2,113.2 42.5,113.2 23.1,93.8 C3.7,74.4 3.7,42.7 23.1,23.3 C39.7,6.8 65,3.9 84.8,16.2 C86.7,17.4 89.2,16.8 90.4,14.9 C91.6,13 91,10.5 89.1,9.3 Z"
                                fill="#17AB13"
                                id="Shape"
                              ></path>{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </span>
                  )}
                  Contain at least one upper and lower case letter{" "}
                </div>
                <div
                  className={
                    inst3 ? "instructions isFilled" : "instructions notFilled"
                  }
                >
                  {!inst3 && (
                    <span>
                      <svg
                        viewBox="0 0 1024 1024"
                        class="icon"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#000000"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          <path
                            d="M512 128C300.8 128 128 300.8 128 512s172.8 384 384 384 384-172.8 384-384S723.2 128 512 128z m0 85.333333c66.133333 0 128 23.466667 179.2 59.733334L273.066667 691.2C236.8 640 213.333333 578.133333 213.333333 512c0-164.266667 134.4-298.666667 298.666667-298.666667z m0 597.333334c-66.133333 0-128-23.466667-179.2-59.733334l418.133333-418.133333C787.2 384 810.666667 445.866667 810.666667 512c0 164.266667-134.4 298.666667-298.666667 298.666667z"
                            fill="#D50000"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  )}
                  {inst3 && (
                    <span>
                      <svg
                        viewBox="0 0 117 117"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        fill="#000000"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <title></title> <desc></desc> <defs></defs>{" "}
                          <g
                            fill="none"
                            fill-rule="evenodd"
                            id="Page-1"
                            stroke="none"
                            stroke-width="1"
                          >
                            {" "}
                            <g fill-rule="nonzero" id="correct">
                              {" "}
                              <path
                                d="M34.5,55.1 C32.9,53.5 30.3,53.5 28.7,55.1 C27.1,56.7 27.1,59.3 28.7,60.9 L47.6,79.8 C48.4,80.6 49.4,81 50.5,81 C50.6,81 50.6,81 50.7,81 C51.8,80.9 52.9,80.4 53.7,79.5 L101,22.8 C102.4,21.1 102.2,18.5 100.5,17 C98.8,15.6 96.2,15.8 94.7,17.5 L50.2,70.8 L34.5,55.1 Z"
                                fill="#17AB13"
                                id="Shape"
                              ></path>{" "}
                              <path
                                d="M89.1,9.3 C66.1,-5.1 36.6,-1.7 17.4,17.5 C-5.2,40.1 -5.2,77 17.4,99.6 C28.7,110.9 43.6,116.6 58.4,116.6 C73.2,116.6 88.1,110.9 99.4,99.6 C118.7,80.3 122,50.7 107.5,27.7 C106.3,25.8 103.8,25.2 101.9,26.4 C100,27.6 99.4,30.1 100.6,32 C113.1,51.8 110.2,77.2 93.6,93.8 C74.2,113.2 42.5,113.2 23.1,93.8 C3.7,74.4 3.7,42.7 23.1,23.3 C39.7,6.8 65,3.9 84.8,16.2 C86.7,17.4 89.2,16.8 90.4,14.9 C91.6,13 91,10.5 89.1,9.3 Z"
                                fill="#17AB13"
                                id="Shape"
                              ></path>{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </span>
                  )}
                  A number and a symbol{" "}
                </div>
                <div
                  className={
                    inst4
                      ? "instructions last-inst isFilled"
                      : "instructions last-inst notFilled"
                  }
                >
                  {!inst4 && (
                    <span>
                      <svg
                        viewBox="0 0 1024 1024"
                        class="icon"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#000000"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          <path
                            d="M512 128C300.8 128 128 300.8 128 512s172.8 384 384 384 384-172.8 384-384S723.2 128 512 128z m0 85.333333c66.133333 0 128 23.466667 179.2 59.733334L273.066667 691.2C236.8 640 213.333333 578.133333 213.333333 512c0-164.266667 134.4-298.666667 298.666667-298.666667z m0 597.333334c-66.133333 0-128-23.466667-179.2-59.733334l418.133333-418.133333C787.2 384 810.666667 445.866667 810.666667 512c0 164.266667-134.4 298.666667-298.666667 298.666667z"
                            fill="#D50000"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  )}
                  {inst4 && (
                    <span>
                      <svg
                        viewBox="0 0 117 117"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        fill="#000000"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <title></title> <desc></desc> <defs></defs>{" "}
                          <g
                            fill="none"
                            fill-rule="evenodd"
                            id="Page-1"
                            stroke="none"
                            stroke-width="1"
                          >
                            {" "}
                            <g fill-rule="nonzero" id="correct">
                              {" "}
                              <path
                                d="M34.5,55.1 C32.9,53.5 30.3,53.5 28.7,55.1 C27.1,56.7 27.1,59.3 28.7,60.9 L47.6,79.8 C48.4,80.6 49.4,81 50.5,81 C50.6,81 50.6,81 50.7,81 C51.8,80.9 52.9,80.4 53.7,79.5 L101,22.8 C102.4,21.1 102.2,18.5 100.5,17 C98.8,15.6 96.2,15.8 94.7,17.5 L50.2,70.8 L34.5,55.1 Z"
                                fill="#17AB13"
                                id="Shape"
                              ></path>{" "}
                              <path
                                d="M89.1,9.3 C66.1,-5.1 36.6,-1.7 17.4,17.5 C-5.2,40.1 -5.2,77 17.4,99.6 C28.7,110.9 43.6,116.6 58.4,116.6 C73.2,116.6 88.1,110.9 99.4,99.6 C118.7,80.3 122,50.7 107.5,27.7 C106.3,25.8 103.8,25.2 101.9,26.4 C100,27.6 99.4,30.1 100.6,32 C113.1,51.8 110.2,77.2 93.6,93.8 C74.2,113.2 42.5,113.2 23.1,93.8 C3.7,74.4 3.7,42.7 23.1,23.3 C39.7,6.8 65,3.9 84.8,16.2 C86.7,17.4 89.2,16.8 90.4,14.9 C91.6,13 91,10.5 89.1,9.3 Z"
                                fill="#17AB13"
                                id="Shape"
                              ></path>{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </span>
                  )}
                  A number and a symbol{" "}
                </div>

                <button
                  className="signup_submit"
                  type="submit"
                  disabled={loading}
                >
                  <div>{loading ? "Loading..." : "Sign Up"}</div>
                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.5459 10.7959L8.79594 14.5459C8.58459 14.7573 8.29795 14.876 7.99906 14.876C7.70018 14.876 7.41353 14.7573 7.20219 14.5459C6.99084 14.3346 6.87211 14.0479 6.87211 13.7491C6.87211 13.4502 6.99084 13.1635 7.20219 12.9522L9.03125 11.125H1.25C0.951631 11.125 0.665483 11.0065 0.454505 10.7955C0.243526 10.5845 0.125 10.2984 0.125 10C0.125 9.70163 0.243526 9.41548 0.454505 9.2045C0.665483 8.99353 0.951631 8.875 1.25 8.875H9.03125L7.20406 7.04594C7.09942 6.94129 7.0164 6.81706 6.95977 6.68033C6.90314 6.5436 6.87399 6.39706 6.87399 6.24906C6.87399 5.95018 6.99272 5.66353 7.20406 5.45219C7.41541 5.24084 7.70205 5.12211 8.00094 5.12211C8.29982 5.12211 8.58647 5.24084 8.79781 5.45219L12.5478 9.20219C12.6526 9.30683 12.7356 9.43112 12.7923 9.56793C12.8489 9.70474 12.8779 9.85139 12.8778 9.99945C12.8776 10.1475 12.8482 10.2941 12.7912 10.4308C12.7343 10.5675 12.6509 10.6915 12.5459 10.7959ZM17 0.625H11.75C11.4516 0.625 11.1655 0.743526 10.9545 0.954505C10.7435 1.16548 10.625 1.45163 10.625 1.75C10.625 2.04837 10.7435 2.33452 10.9545 2.5455C11.1655 2.75647 11.4516 2.875 11.75 2.875H16.625V17.125H11.75C11.4516 17.125 11.1655 17.2435 10.9545 17.4545C10.7435 17.6655 10.625 17.9516 10.625 18.25C10.625 18.5484 10.7435 18.8345 10.9545 19.0455C11.1655 19.2565 11.4516 19.375 11.75 19.375H17C17.4973 19.375 17.9742 19.1775 18.3258 18.8258C18.6775 18.4742 18.875 17.9973 18.875 17.5V2.5C18.875 2.00272 18.6775 1.52581 18.3258 1.17417C17.9742 0.822544 17.4973 0.625 17 0.625Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </form>
            </>
          )}

          {modalOpen && (
            <form
              className="thirdparty_email_modal"
              // onSubmit={handleResendEmail}
            >
              <div className="modal_subtitle bold">
                Why are you not getting the code:
              </div>

              <ul>
                <li>Check your spam folder.</li>
                <li>Incorrect email address</li>
              </ul>
              {/* <InputLabel className="modal_label" htmlFor="email">
        Re-enter email address
            </InputLabel> */}

              <Input
                className="thirdparty_modal_input"
                type="text"
                placeholder="Re-enter email address"
                value={email}
                name="reenter-email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  // setErrorPresent(false);
                }}
              />
              <button
                className="modal_button"
                type="submit"
                onClick={handleResendEmail}
              >
                {loading ? "Loading..." : "Resend Code"}
              </button>
            </form>
          )}
        </div>
      </div>
      {modalOpen && <div className="modal" onClick={handleModalClose}></div>}
      {errorModalOpen && <div className="errorModal" onClick={handleErrorModalClose}></div>}
      {errorModalOpen && (
              <div className="error_modal">
                <div className="modal_title">
                  {errorTitle}
                </div>

               
                <div className="modal_subtitle">
                {errorText}
                </div>
                {errorButtonAction === "login"  ? <button
                  className="error_modal_button"
                  type="submit"
                  onClick={() => navigate('/third-party/login')}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Login"}
                </button> : errorButtonAction === "Request OTP" ?
                <button
                  className="error_modal_button"
                  type="submit"
                  onClick={() => {
                    setErrorModalOpen(false);
                    handleModalOpen();
                  }}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Request new OTP"}
                </button> :
                <button
                  className="error_modal_button"
                  type="submit"
                  onClick={() => setErrorModalOpen(false)}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Okay"}
                </button>
                }
              </div>
            )}
    </div>
  );
};

export default Signup;
