import { useEffect, useRef, useState } from "react";
import "../../styles/UserDashboard/OnboardingTemplate.scss";
import {
  Autocomplete,
  Box,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { submitCcvTemplate, submitSubCcvTemplate } from "../../apis/General/users";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import projectDscDocument from "../../documents/CLOSECARBON PROJECT DESCRIPTION TEMPLATE.docx";
import baseYearInventoryDocument from "../../documents/Organization Base-year GHG Inventory Template.xlsx";
import { countries } from "../../data/countries";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import { useWeb3Modal } from "@web3modal/ethers/react";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract, formatUnits } from "ethers";

export default function VerifiersOnboardingTemplates() {
  const auth = useSelector((state) => state.auth);
  const pathname = useLocation();
  const navigate = useNavigate();
  const [stage, setStage] = useState(1);
  const [stakeStage, setStakeStage] = useState(null);
  const [stakeAmount, setStakeAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [filled, setFilled] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorText, setErrorText] = useState("hello");
  const [errorTitle, setErrorTitle] = useState("hello");
  const [errorButtonAction, setErrorButtonAction] = useState("login");
  const [userId, setUserId] = useState("");
  const [protocols, setProtocols] = useState("");
  const [web3Modal, setWeb3Modal] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [userAccount, setUserAccount] = useState(null);
  const [balance, setBalance] = useState(null);
  const [stakeLoading, setStakeLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [verifierType, setVerifierType] = useState("");

  // For SUB-CCV
  const [verifierModal, setVerifierModal] = useState(true);
  const [misappropriation, setMisappropriation] = useState(""); //This is also for CCV!
  const [ccvBody, setCcvBody] = useState(""); //This is also for CCV!
  // For SUB-CCV

  // For CCV
  const [organizationName, setOrganizationName] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [organizationAddress, setOrganizationAddress] = useState("");
  const [organizationEmail, setOrganizationEmail] = useState("");
  const [authorizedName, setAuthorizedName] = useState("");
  // For CCV

  //   useEffect(() => {
  // console.log('stakeStage', stakeStage);
  //   }, [stakeStage])

  //   useEffect(() => {
  //     console.log('pathname?.plan', pathname?.plan);
  // if(pathname?.plan === "verifiers") {
  //   setStakeStage("0")
  // }
  //   }, [])

  // 1. Get projectId
  // const projectId = "14f5df1eed8d25d690e259ace4b1f2ca";

  // // 2. Set chains
  // const mainnet = {
  //   chainId: 1,
  //   name: "Ethereum",
  //   currency: "ETH",
  //   explorerUrl: "https://etherscan.io",
  //   rpcUrl: "https://cloudflare-eth.com",
  // };

  // const polygonMumbai = {
  //   chainId: 80001, // Chain ID for Polygon Mumbai testnet
  //   name: "Polygon Mumbai",
  //   currency: "MATIC",
  //   explorerUrl: "https://mumbai.polygonscan.com",
  //   rpcUrl: "https://matic-mumbai.chainstacklabs.com", // Public RPC endpoint for Polygon Mumbai
  // };

  // 3. Create a metadata object
  // const metadata = {
  //   name: "My Website",
  //   description: "My Website description",
  //   url: "https://dev.closecrabon.com", // origin must match your domain & subdomain
  //   icons: ["https://avatars.mywebsite.com/"],
  // };

  // 4. Create Ethers config
  // const ethersConfig = defaultConfig({
  //   /*Required*/
  //   metadata,

  //   /*Optional*/
  //   enableEIP6963: true, // true by default
  //   enableInjected: true, // true by default
  //   enableCoinbase: true, // true by default
  //   rpcUrl: "...", // used for the Coinbase SDK
  //   defaultChainId: 1, // used for the Coinbase SDK
  // });

  // 5. Create a Web3Modal instance
  // createWeb3Modal({
  //   ethersConfig,
  //   chains: [mainnet],
  //   projectId,
  //   enableAnalytics: true, // Optional - defaults to your Cloud configuration
  // });

  // const { open } = useWeb3Modal();

  // const USDTAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7";

  // The ERC-20 Contract ABI, which is a common contract interface
  // for tokens (this is the Human-Readable ABI format)
  // const USDTAbi = [
  //   "function name() view returns (string)",
  //   "function symbol() view returns (string)",
  //   "function balanceOf(address) view returns (uint)",
  //   "function transfer(address to, uint amount)",
  //   "event Transfer(address indexed from, address indexed to, uint amount)",
  // ];

  // const { address, chainId, isConnected } = useWeb3ModalAccount();
  // const { walletProvider } = useWeb3ModalProvider();

  // async function getBalance() {
  //   try {
  //     console.log("users address", address);
  //     if (!isConnected) throw Error("User disconnected");

  //     const ethersProvider = new BrowserProvider(walletProvider);
  //     const signer = await ethersProvider.getSigner();
  //     // The Contract object
  //     const USDTContract = new Contract(USDTAddress, USDTAbi, signer);
  //     const USDTBalance = await USDTContract.balanceOf(address);

  //     console.log("buhari", formatUnits(USDTBalance, 18));
  //     setBalance(formatUnits(USDTBalance, 18));
  //     setStakeStage(3);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // useEffect(() => {
  //   if (address && pathname?.plan === "verifiers") {
  //     getBalance();
  //   }
  // }, [address]);

  // useEffect(() => {
  //   const providerOptions = {};
  //   const newWeb3Modal = new Web3Modal({
  //     network: "mainnet", // or 'ropsten', 'rinkeby', etc.
  //     cacheProvider: true,
  //     providerOptions,
  //   });
  //   setWeb3Modal(newWeb3Modal);
  // }, []);

  // const connectWallet = async () => {
  //   try {
  //     const provider = await web3Modal.connect();
  //     const newWeb3 = new Web3(provider);
  //     const accounts = await newWeb3.eth.getAccounts();
  //     setWeb3(newWeb3);
  //     setUserAccount(accounts[0]);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   const getTokenBalance = async (tokenAddress, userAccount) => {
  //     const contract = new web3.eth.Contract(tokenABI, tokenAddress);
  //     const balance = await contract.methods.balanceOf(userAccount).call();
  //     return web3.utils.fromWei(balance, 'ether');
  //   };
  // }, [])

  const handleShowError = (title, body, action = "ok") => {
    setErrorTitle(title);
    setErrorText(body);
    setErrorButtonAction(action);
    handleErrorModalOpen();
  };

  const handleVerifiersStep2 = (e) => {
    e.preventDefault();
    if (!/^(0x)[0-9a-f]{40}$/i.test(walletAddress)) {
      alert("Invalid wallet address");
      return false;
    } else {
      setStage(2);
    }
  };

  const handleSubmitVerifiers = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (protocols === "") {
      alert(
        "Please answer the question below. Do you guaranteed to follow the necessary protocols?"
      );
    } else if (protocols === "false") {
      alert(
        "CloseCarbon does not allow users who do not guarantee they will follow the necessary protocols?"
      );
    } else if (!terms) {
      alert("Please agree to our terms and conditions before proceeding");
    } else if (misappropriation === "") {
      alert(
        "Please answer the question below. Have you ever been involved in any carbon misappropriation?"
      );
    } else if (misappropriation === "true") {
      alert(
        "CloseCarbon does not allow users involved in misappropriation to use our platform"
      );
    } else if (!privacy) {
      alert("Please agree to our privacy policy  before proceeding");
    } else {
      let response;
      if (verifierType === "ccv") {
         response = await submitCcvTemplate(
          pathname?.pathname?.split('/')[2],
          verifierType,
          firstName,
          lastName,
          email,
          organizationName,
          organizationAddress,
          organizationEmail,
          registrationNumber,
          walletAddress,
          protocols,
          phone
        );
      } else if(verifierType === "subCcv") {
         response = await submitSubCcvTemplate(
          pathname?.pathname?.split('/')[2],
          verifierType,
          firstName,
          lastName,
          email,
          phone,
          ccvBody,
          walletAddress,
          protocols
        );
      }

      console.log('repsonse', response);

      if (response?.data?.statusCode === 200) {
        // toast.success(response?.data?.message);
        navigate(
          `/third-party/signup/${response.data.message}/${verifierType}`
        );
        localStorage.setItem("demosent", "true");
      } else if (
        response?.data?.message ===
        "This email has been registered. Kindly login"
      ) {
        handleShowError(
          "Oops email already registered",
          "It appears this email has already been registered. If you’ve forgotten your password, click “Forgot Password” on the login screen.",
          "login"
        );
      } else if (
        response?.data?.message === "KYC submitted. Kindly verify your email."
      ) {
        handleShowError(
          "Oops email already existed",
          "This email already exists. Kindly click the button below to input the otp sent to your email to activate or request for a new OTP",
          "otp"
        );
        setUserId(response?.data?.userId);
      } else if (
        response?.data?.message ===
        "This email has been registered. Kindly complete the sign up process"
      ) {
        handleShowError(
          "Oops email already registered",
          "It appears this email has already been registered.  Kindly click the button below to complete the sign up process",
          "register"
        );
        setUserId(response?.data?.userId);
      } else {
        toast.error(response?.data?.message);
      }

      console.log("response from verifiers", response);
    }

    setLoading(false);
  };

  const handleErrorModalClose = (e) => {
    if (e.target.classList.contains("errorModal")) {
      setErrorModalOpen(false);
    }
  };

  const handleErrorModalOpen = () => {
    setErrorModalOpen(true);
  };

  const handleProjectDescriptionDownload = () => {
    const link = document.createElement("a");
    link.href = projectDscDocument;
    link.download = "CLOSECARBON PROJECT DESCRIPTION TEMPLATE.docx"; // Set the desired file name for the download
    link.click();
  };
  const handleDownloadbaseYearInventoryDocument = () => {
    const link = document.createElement("a");
    link.href = baseYearInventoryDocument;
    link.download = "Organisation Base-year GHG Inventory Template.xlsx"; // Set the desired file name for the download
    link.click();
  };

  const handleSelectVerificationStatus = () => {
    if (verifierType === "") {
      alert("Please select your verification status");
    } else {
      setVerifierModal(false);
    }
  };

  console.log("stage", stakeStage, !!stakeStage);

  return (
    <div className="onboarding-template">
      <div className="onboarding-template__title">
        You’re a step closer to CloseCarbon
      </div>
      <div className="onboarding-template__subtitle">
        Fill this simple form to enable us learn about your facility.
      </div>
      <div className="steps">
        <div className="circle circle_active">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.75 8.75L6.25 12.25L13.25 4.75"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className="line"></div>
        <div className={filled ? "circle circle_active" : "circle"}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.75 8.75L6.25 12.25L13.25 4.75"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>

      {stage === 1 && (
        <form onSubmit={handleVerifiersStep2}>
          <label className="onboarding-template__input-label" htmlFor="name">
            First name
          </label>

          <input
            className="onboarding-template__input"
            required
            id="name"
            type="text"
            placeholder="Enter your first name"
            name="name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <label className="onboarding-template__input-label" htmlFor="name">
            Last name
          </label>

          <input
            className="onboarding-template__input"
            required
            id="name"
            type="text"
            placeholder="Enter your last name"
            name="name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <label className="onboarding-template__input-label" htmlFor="email">
            Email
          </label>

          <input
            className="onboarding-template__input"
            required
            id="email"
            type="email"
            placeholder="Enter email address"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label className="onboarding-template__input-label" htmlFor="phone">
            Phone number
          </label>

          <input
            className="onboarding-template__input"
            required
            id="phone"
            type="text"
            placeholder="Enter phone number"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />

          {verifierType === "ccv" && (
            <>
              <label
                className="onboarding-template__input-label"
                htmlFor="phone"
              >
                Organisation name
              </label>

              <input
                className="onboarding-template__input"
                required
                id="phone"
                type="text"
                placeholder="Full legal name of organisation"
                name="phone"
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value)}
              />

              <label
                className="onboarding-template__input-label"
                htmlFor="phone"
              >
                Organisation Address
              </label>

              <input
                className="onboarding-template__input"
                required
                id="phone"
                type="text"
                placeholder="Enter the full legal name of organisation"
                name="phone"
                value={organizationAddress}
                onChange={(e) => setOrganizationAddress(e.target.value)}
              />

              <label
                className="onboarding-template__input-label"
                htmlFor="phone"
              >
                Organisation email
              </label>

              <input
                className="onboarding-template__input"
                required
                id="phone"
                type="text"
                placeholder="Organisation email address"
                name="phone"
                value={organizationEmail}
                onChange={(e) => setOrganizationEmail(e.target.value)}
              />

              <label
                className="onboarding-template__input-label"
                htmlFor="phone"
              >
                Organisation registration number
              </label>

              <input
                className="onboarding-template__input"
                required
                id="phone"
                type="text"
                placeholder="Full legal name of organisation"
                name="phone"
                value={registrationNumber}
                onChange={(e) => setRegistrationNumber(e.target.value)}
              />
            </>
          )}

          {verifierType === "subCcv" && (
            <>
              <label
                className="onboarding-template__input-label"
                htmlFor="phone"
              >
                Which verification body are you registered with
              </label>

              <select name="" id=""
               className="onboarding-template__input"
               required
               value={ccvBody}
               onChange={(e) => setCcvBody(e.target.value)}
              >
                <option value="">Select verification body</option>
                <option value="Climate Certifications Limited">Climate Certifications Limited</option>
              </select>

             
            </>
          )}

          <label className="onboarding-template__input-label" htmlFor="phone">
            Wallet address
          </label>
          <div className="onboarding-template__info">
            You can create a wallet and get an address on the <a href="https://metamask.io/" target="_blank" >MetaMask</a> website
          </div>
          <input
            className="onboarding-template__input"
            required
            id="walletAddress"
            type="text"
            placeholder="Enter your Ethereum Wallet Address (e.g., 0x1234...abcd)"
            name="walletAddress"
            value={walletAddress}
            onChange={(e) => setWalletAddress(e.target.value)}
          />
          <div className="onboarding-template__input-text">
          Note: Do not disclose your private key, please enter your Ethereum Wallet Address
          </div>
          <button
            className="onboarding-template__button"
            type="submit"
            disabled={loading}
          >
            <div>{loading ? "Loading..." : "Submit"}</div>
          </button>
        </form>
      )}
      {stage === 2 && (
        <form onSubmit={handleSubmitVerifiers}>
          <div className="onboarding-template__grid">
            <div className="onboarding-template__grid__title">
              Have you ever been involved in any carbon misappropriation?
            </div>
            <div className="onboarding-template__grid__check-box">
              <div>
                {misappropriation !== "true" && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setMisappropriation("true")}
                  >
                    <rect
                      x="1"
                      y="1"
                      width="16"
                      height="16"
                      rx="4"
                      stroke="url(#paint0_linear_2889_18043)"
                      stroke-width="1.33333"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2889_18043"
                        x1="1"
                        y1="1.36719"
                        x2="20.0689"
                        y2="10.7886"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#006633" />
                        <stop offset="1" stop-color="#02A4CC" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}
                {misappropriation === "true" && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setMisappropriation("")}
                  >
                    <rect
                      x="1"
                      y="1"
                      width="16"
                      height="16"
                      rx="4"
                      stroke="url(#paint0_linear_2889_18037)"
                      stroke-width="1.33333"
                    />
                    <path
                      d="M5.5 9.49992L7.83333 11.8333L12.5 6.83325"
                      stroke="url(#paint1_linear_2889_18037)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2889_18037"
                        x1="1"
                        y1="1.36719"
                        x2="20.0689"
                        y2="10.7886"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#006633" />
                        <stop offset="1" stop-color="#02A4CC" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_2889_18037"
                        x1="5.5"
                        y1="6.948"
                        x2="12.5203"
                        y2="11.8039"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#006633" />
                        <stop offset="1" stop-color="#02A4CC" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}
                Yes
              </div>
              <div>
                {misappropriation !== "false" && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setMisappropriation("false")}
                  >
                    <rect
                      x="1"
                      y="1"
                      width="16"
                      height="16"
                      rx="4"
                      stroke="url(#paint0_linear_2889_18043)"
                      stroke-width="1.33333"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2889_18043"
                        x1="1"
                        y1="1.36719"
                        x2="20.0689"
                        y2="10.7886"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#006633" />
                        <stop offset="1" stop-color="#02A4CC" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}
                {misappropriation === "false" && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setMisappropriation("")}
                  >
                    <rect
                      x="1"
                      y="1"
                      width="16"
                      height="16"
                      rx="4"
                      stroke="url(#paint0_linear_2889_18037)"
                      stroke-width="1.33333"
                    />
                    <path
                      d="M5.5 9.49992L7.83333 11.8333L12.5 6.83325"
                      stroke="url(#paint1_linear_2889_18037)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2889_18037"
                        x1="1"
                        y1="1.36719"
                        x2="20.0689"
                        y2="10.7886"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#006633" />
                        <stop offset="1" stop-color="#02A4CC" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_2889_18037"
                        x1="5.5"
                        y1="6.948"
                        x2="12.5203"
                        y2="11.8039"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#006633" />
                        <stop offset="1" stop-color="#02A4CC" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}
                No
              </div>
            </div>
            <div className="onboarding-template__grid__title">
              Do you guaranteed to follow the necessary protocols?
            </div>
            <div className="onboarding-template__grid__check-box">
              <div>
                {protocols !== "true" && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setProtocols("true")}
                  >
                    <rect
                      x="1"
                      y="1"
                      width="16"
                      height="16"
                      rx="4"
                      stroke="url(#paint0_linear_2889_18043)"
                      stroke-width="1.33333"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2889_18043"
                        x1="1"
                        y1="1.36719"
                        x2="20.0689"
                        y2="10.7886"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#006633" />
                        <stop offset="1" stop-color="#02A4CC" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}
                {protocols === "true" && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setProtocols("")}
                  >
                    <rect
                      x="1"
                      y="1"
                      width="16"
                      height="16"
                      rx="4"
                      stroke="url(#paint0_linear_2889_18037)"
                      stroke-width="1.33333"
                    />
                    <path
                      d="M5.5 9.49992L7.83333 11.8333L12.5 6.83325"
                      stroke="url(#paint1_linear_2889_18037)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2889_18037"
                        x1="1"
                        y1="1.36719"
                        x2="20.0689"
                        y2="10.7886"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#006633" />
                        <stop offset="1" stop-color="#02A4CC" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_2889_18037"
                        x1="5.5"
                        y1="6.948"
                        x2="12.5203"
                        y2="11.8039"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#006633" />
                        <stop offset="1" stop-color="#02A4CC" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}
                Yes
              </div>
              <div>
                {protocols !== "false" && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setProtocols("false")}
                  >
                    <rect
                      x="1"
                      y="1"
                      width="16"
                      height="16"
                      rx="4"
                      stroke="url(#paint0_linear_2889_18043)"
                      stroke-width="1.33333"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2889_18043"
                        x1="1"
                        y1="1.36719"
                        x2="20.0689"
                        y2="10.7886"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#006633" />
                        <stop offset="1" stop-color="#02A4CC" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}
                {protocols === "false" && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setProtocols("")}
                  >
                    <rect
                      x="1"
                      y="1"
                      width="16"
                      height="16"
                      rx="4"
                      stroke="url(#paint0_linear_2889_18037)"
                      stroke-width="1.33333"
                    />
                    <path
                      d="M5.5 9.49992L7.83333 11.8333L12.5 6.83325"
                      stroke="url(#paint1_linear_2889_18037)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2889_18037"
                        x1="1"
                        y1="1.36719"
                        x2="20.0689"
                        y2="10.7886"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#006633" />
                        <stop offset="1" stop-color="#02A4CC" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_2889_18037"
                        x1="5.5"
                        y1="6.948"
                        x2="12.5203"
                        y2="11.8039"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#006633" />
                        <stop offset="1" stop-color="#02A4CC" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}
                No
              </div>
            </div>
            <div className="onboarding-template__grid__title">
              By registering, I confirm that I have read the{" "}
              <span
                onClick={() => {
                  window.open(
                    "/terms-and-conditions",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                Terms and Conditions
              </span>
            </div>
            <div className="onboarding-template__grid__check-box">
              <div>
                {!terms && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setTerms(true)}
                  >
                    <rect
                      x="1"
                      y="1"
                      width="16"
                      height="16"
                      rx="4"
                      stroke="url(#paint0_linear_2889_18043)"
                      stroke-width="1.33333"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2889_18043"
                        x1="1"
                        y1="1.36719"
                        x2="20.0689"
                        y2="10.7886"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#006633" />
                        <stop offset="1" stop-color="#02A4CC" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}

                {terms && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setTerms(false)}
                  >
                    <rect
                      x="1"
                      y="1"
                      width="16"
                      height="16"
                      rx="4"
                      stroke="url(#paint0_linear_2889_18037)"
                      stroke-width="1.33333"
                    />
                    <path
                      d="M5.5 9.49992L7.83333 11.8333L12.5 6.83325"
                      stroke="url(#paint1_linear_2889_18037)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2889_18037"
                        x1="1"
                        y1="1.36719"
                        x2="20.0689"
                        y2="10.7886"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#006633" />
                        <stop offset="1" stop-color="#02A4CC" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_2889_18037"
                        x1="5.5"
                        y1="6.948"
                        x2="12.5203"
                        y2="11.8039"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#006633" />
                        <stop offset="1" stop-color="#02A4CC" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}
              </div>
            </div>
            <div className="onboarding-template__grid__title">
              By registering, I confirm that I have read the{" "}
              <span
                onClick={() => {
                  window.open(
                    "/privacy-policy",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                Privacy Policy
              </span>
            </div>
            <div className="onboarding-template__grid__check-box">
              <div>
                {!privacy && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setPrivacy(true)}
                  >
                    <rect
                      x="1"
                      y="1"
                      width="16"
                      height="16"
                      rx="4"
                      stroke="url(#paint0_linear_2889_18043)"
                      stroke-width="1.33333"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2889_18043"
                        x1="1"
                        y1="1.36719"
                        x2="20.0689"
                        y2="10.7886"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#006633" />
                        <stop offset="1" stop-color="#02A4CC" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}

                {privacy && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setPrivacy(false)}
                  >
                    <rect
                      x="1"
                      y="1"
                      width="16"
                      height="16"
                      rx="4"
                      stroke="url(#paint0_linear_2889_18037)"
                      stroke-width="1.33333"
                    />
                    <path
                      d="M5.5 9.49992L7.83333 11.8333L12.5 6.83325"
                      stroke="url(#paint1_linear_2889_18037)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2889_18037"
                        x1="1"
                        y1="1.36719"
                        x2="20.0689"
                        y2="10.7886"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#006633" />
                        <stop offset="1" stop-color="#02A4CC" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_2889_18037"
                        x1="5.5"
                        y1="6.948"
                        x2="12.5203"
                        y2="11.8039"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#006633" />
                        <stop offset="1" stop-color="#02A4CC" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}
              </div>
            </div>
          </div>
          <button
            className="onboarding-template__button"
            type="submit"
            disabled={loading}
          >
            <div>{loading ? "Loading..." : "Submit"}</div>
          </button>
        </form>
      )}

      {errorModalOpen && (
        <div className="errorModal" onClick={handleErrorModalClose}></div>
      )}
      {errorModalOpen && (
        <div className="error_modal">
          <div className="modal_title">{errorTitle}</div>

          <div className="modal_subtitle">{errorText}</div>
          {errorButtonAction === "login" ? (
            <button
              className="error_modal_button"
              type="submit"
              onClick={() => navigate("/login")}
              disabled={loading}
            >
              {loading ? "Loading..." : "Login"}
            </button>
          ) : errorButtonAction === "register" ? (
            <button
              className="error_modal_button"
              type="submit"
              onClick={() => navigate(`/signup/${userId}/3`)}
              disabled={loading}
            >
              {loading ? "Loading..." : "Okay"}
            </button>
          ) : errorButtonAction === "otp" ? (
            <button
              className="error_modal_button"
              type="submit"
              onClick={() => navigate(`/signup/${userId}`)}
              disabled={loading}
            >
              {loading ? "Loading..." : "Okay"}
            </button>
          ) : (
            <button
              className="error_modal_button"
              type="submit"
              onClick={() => setErrorModalOpen(false)}
              disabled={loading}
            >
              {loading ? "Loading..." : "Okay"}
            </button>
          )}
        </div>
      )}

      {!!stakeStage && <div className="onboarding-template__modal"></div>}
      {verifierModal && <div className="onboarding-template__modal"></div>}

      {verifierModal && (
        <div className="onboarding-template__vv-card">
          <div className="onboarding-template__vv-card__title">
            Select your verification status
          </div>
          <div className="onboarding-template__vv-card__group">
            {verifierType !== "ccv" && (
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setVerifierType("ccv")}
              >
                <rect
                  x="1"
                  y="1"
                  width="24"
                  height="24"
                  rx="6"
                  stroke="url(#paint0_linear_2475_15086)"
                  stroke-width="2"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2475_15086"
                    x1="1"
                    y1="1.55078"
                    x2="29.6033"
                    y2="15.6829"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#006633" />
                    <stop offset="1" stop-color="#02A4CC" />
                  </linearGradient>
                </defs>
              </svg>
            )}
            {verifierType === "ccv" && (
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setVerifierType("")}
              >
                <rect
                  x="1"
                  y="1"
                  width="24"
                  height="24"
                  rx="6"
                  stroke="url(#paint0_linear_2475_15083)"
                  stroke-width="2"
                />
                <path
                  d="M7.75 13.75L11.25 17.25L18.25 9.75"
                  stroke="url(#paint1_linear_2475_15083)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2475_15083"
                    x1="1"
                    y1="1.55078"
                    x2="29.6033"
                    y2="15.6829"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#006633" />
                    <stop offset="1" stop-color="#02A4CC" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2475_15083"
                    x1="7.75"
                    y1="9.92212"
                    x2="18.2804"
                    y2="17.206"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#006633" />
                    <stop offset="1" stop-color="#02A4CC" />
                  </linearGradient>
                </defs>
              </svg>
            )}
            Are you an accredited verifier with a recognized accrediting body?
          </div>
          <div className="onboarding-template__vv-card__group">
            {verifierType !== "subCcv" && (
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setVerifierType("subCcv")}
              >
                <rect
                  x="1"
                  y="1"
                  width="24"
                  height="24"
                  rx="6"
                  stroke="url(#paint0_linear_2475_15086)"
                  stroke-width="2"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2475_15086"
                    x1="1"
                    y1="1.55078"
                    x2="29.6033"
                    y2="15.6829"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#006633" />
                    <stop offset="1" stop-color="#02A4CC" />
                  </linearGradient>
                </defs>
              </svg>
            )}
            {verifierType === "subCcv" && (
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setVerifierType("")}
              >
                <rect
                  x="1"
                  y="1"
                  width="24"
                  height="24"
                  rx="6"
                  stroke="url(#paint0_linear_2475_15083)"
                  stroke-width="2"
                />
                <path
                  d="M7.75 13.75L11.25 17.25L18.25 9.75"
                  stroke="url(#paint1_linear_2475_15083)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2475_15083"
                    x1="1"
                    y1="1.55078"
                    x2="29.6033"
                    y2="15.6829"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#006633" />
                    <stop offset="1" stop-color="#02A4CC" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2475_15083"
                    x1="7.75"
                    y1="9.92212"
                    x2="18.2804"
                    y2="17.206"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#006633" />
                    <stop offset="1" stop-color="#02A4CC" />
                  </linearGradient>
                </defs>
              </svg>
            )}
            Are you an individual seeking to become a sub verifier?
          </div>

          <button
            className="onboarding-template__vv-card__button"
            onClick={handleSelectVerificationStatus}
          >
            Continue
          </button>
        </div>
      )}

      {stakeStage === "0" && (
        <div className="onboarding-template__modal-card onboarding-template__stake1">
          <div className="onboarding-template__stake1__title">
            Have you acquired CCT
          </div>
          <div className="onboarding-template__stake1__button-group">
            <button onClick={() => setStakeStage(2)}>Yes</button>
            <button onClick={() => setStakeStage(-1)}>No</button>
          </div>
        </div>
      )}
      {stakeStage === 2 && (
        <div className="onboarding-template__modal-card onboarding-template__stake2">
          <div className="onboarding-template__stake2__title">
            Stake CloseCarbon token
          </div>
          <div className="onboarding-template__stake2__subtitle">
            Stake CCT and get rewards
          </div>

          <div className="onboarding-template__stake2__form">
            <input
              className="onboarding-template__stake2__form__input"
              type="text"
              placeholder="CCT amount"
              value={stakeAmount}
              onChange={(e) => setStakeAmount(e?.target?.value)}
            />

            <button className="onboarding-template__stake2__form__button">
              Max
            </button>
          </div>
          {userAccount ? (
            <p>Connected Account: {userAccount}</p>
          ) : (
            <button
              className="onboarding-template__stake2__button"
              // onClick={connectWallet}
              // onClick={() => open()}
            >
              Connect wallet
            </button>
          )}

          <div className="onboarding-template__stake2__disclaimer">
            Note: You’re staking for 6months{" "}
          </div>
          {/* <button onClick={() => open()}>Open Connect Modal</button>
      <button onClick={() => open({ view: 'Networks' })}>Open Network Modal</button> */}
        </div>
      )}
      {stakeStage === 3 && !stakeLoading ? (
        <div className="onboarding-template__modal-card onboarding-template__stake4">
          <div className="onboarding-template__stake4__title">
            Stake CloseCarbon token
          </div>
          <div className="onboarding-template__stake4__subtitle">
            Stake CCT and get rewards
          </div>
          <div className="onboarding-template__stake4__group">
            <div className="onboarding-template__stake4__group__available">
              <div>
                Available to stake{" "}
                <svg
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="#0D924F" />
                </svg>
              </div>

              <div>{balance} CCT</div>
            </div>

            <div className="onboarding-template__stake4__group__address">
              {/* {address?.split("").slice(0, 6)?.join("")}....
              {address
                ?.split("")
                .slice(
                  address?.split("")?.length - 6,
                  address?.split("")?.length
                )
                ?.join("")} */}
            </div>
          </div>

          <div className="onboarding-template__stake4__divider"></div>
          <div className="onboarding-template__stake4__group2">
            <div className="onboarding-template__stake4__group2__available">
              <div>Staked amount</div>

              <div>{stakeAmount} CCT</div>
            </div>
          </div>

          <div className="onboarding-template__stake4__divider"></div>

          <div className="onboarding-template__stake4__form">
            <input
              className="onboarding-template__stake4__form__input"
              type="text"
              placeholder="CCT amount"
              value={stakeAmount}
              onChange={(e) => setStakeAmount(e?.target?.value)}
            />

            <button className="onboarding-template__stake4__form__button">
              Max
            </button>
          </div>

          <button
            className="onboarding-template__stake4__button"
            onClick={() => {
              setStakeLoading(true);
              if (stakeAmount === "") {
                alert("Please state the amount you wish to stake");
              } else {
                setStakeStage(4);
              }
              setStakeLoading(false);
            }}
          >
            Submit
          </button>

          <div className="onboarding-template__stake4__disclaimer">
            Note: You’re staking for 6months{" "}
          </div>
        </div>
      ) : (
        ""
      )}
      {/* <div className="onboarding-template__modal-card onboarding-template__stake5">
        <div className="onboarding-template__stake5__close">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
              fill="#141414"
            />
          </svg>
        </div>

        <svg
          className="onboarding-template__stake5__fail"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="23.5" stroke="#F31D1D" />
          <path
            d="M24.0001 22.304L29.9397 16.3643L31.6367 18.0613L25.6971 24.001L31.6367 29.9407L29.9397 31.6377L24.0001 25.6981L18.0603 31.6377L16.3633 29.9407L22.303 24.001L16.3633 18.0613L18.0603 16.3643L24.0001 22.304Z"
            fill="#F31D1D"
          />
        </svg>
        <div className="onboarding-template__stake5__title">
          Transaction Failed
        </div>
        <div className="onboarding-template__stake5__subtitle">
          Not enough CCT for gas
        </div>
      </div> */}
      {stakeLoading && (
        <div className="onboarding-template__modal-card onboarding-template__stake6">
          <div
            className="onboarding-template__stake6__close pointer"
            onClick={() => setStakeStage(null)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                fill="#141414"
              />
            </svg>
          </div>

          <svg
            className="onboarding-template__stake6__success"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="24" cy="24" r="23" stroke="#E1F9ED" stroke-width="2" />
            <mask id="path-2-inside-1_1423_5574" fill="white">
              <path d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM1.99632 24C1.99632 36.1523 11.8477 46.0037 24 46.0037C36.1523 46.0037 46.0037 36.1523 46.0037 24C46.0037 11.8477 36.1523 1.99632 24 1.99632C11.8477 1.99632 1.99632 11.8477 1.99632 24Z" />
            </mask>
            <path
              d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM1.99632 24C1.99632 36.1523 11.8477 46.0037 24 46.0037C36.1523 46.0037 46.0037 36.1523 46.0037 24C46.0037 11.8477 36.1523 1.99632 24 1.99632C11.8477 1.99632 1.99632 11.8477 1.99632 24Z"
              stroke="#0D924F"
              stroke-width="4"
              mask="url(#path-2-inside-1_1423_5574)"
            />
            <path
              d="M16.125 25.125L21.375 30.375L31.875 19.125"
              stroke="#0D924F"
              stroke-width="2.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div className="onboarding-template__stake6__title">
            You are now staking 1. 03 CCT
          </div>
          <div className="onboarding-template__stake6__subtitle">
            Staking 0.02 CCT. You will receive 1.03 stCCT
          </div>
          <div className="onboarding-template__stake6__subsubtitle">
            Confirm transaction post-loading
          </div>
        </div>
      )}
      {stakeStage === 4 && (
        <div className="onboarding-template__modal-card onboarding-template__stake7">
          <div className="onboarding-template__stake7__title">
            Stake CloseCarbon token
          </div>
          <div className="onboarding-template__stake7__subtitle">
            Stake CCT and get rewards
          </div>
          <div className="onboarding-template__stake7__group">
            <div className="onboarding-template__stake7__group__available">
              <div>Wallet address</div>
            </div>

            <div className="onboarding-template__stake7__group__address">
              {/* {address?.split("").slice(0, 6)?.join("")}....
              {address
                ?.split("")
                .slice(
                  address?.split("")?.length - 6,
                  address?.split("")?.length
                )
                ?.join("")} */}
            </div>
          </div>

          <div className="onboarding-template__stake7__divider"></div>
          <div className="onboarding-template__stake7__group2">
            <div className="onboarding-template__stake7__group2__available">
              <div>Total</div>

              <div>Amount staked:</div>
            </div>

            <div className="onboarding-template__stake7__group2__amount">
              <div># 50,000</div>
              <div>{stakeAmount} CCT</div>
            </div>
          </div>

          <div className="onboarding-template__stake7__divider"></div>

          <div className="onboarding-template__stake7__button-group">
            <button onClick={() => setStakeStage(5)}>Confirm</button>
            <button onClick={() => setStakeStage(3)}>Reject</button>
          </div>
        </div>
      )}

      {stakeStage === -1 && (
        <div className="onboarding-template__modal-card onboarding-template__stake8">
          <div className="onboarding-template__stake8__title">
            Acquire CloseCarbon token
          </div>
          <div className="onboarding-template__stake8__subtitle">
            Get token from decentralized market listed <br /> below
          </div>

          <div className="onboarding-template__stake8__grid">
            <div
              onClick={() => {
                window.open("https://app.uniswap.org/");
                setStage(1);
              }}
            >
              Uniswap
            </div>
            <div
              onClick={() => {
                window.open("https://pancakeswap.finance/swap");
                setStage(1);
              }}
            >
              Pancakeswap
            </div>
            <div
              onClick={() => {
                window.open("https://www.sushi.com/swap");
                setStage(1);
              }}
            >
              Sushiswap
            </div>
          </div>
        </div>
      )}

      {stakeStage === 5 && (
        <div className="onboarding-template__modal-card onboarding-template__stake9">
          <div
            className="onboarding-template__stake9__close"
            onClick={() => setStakeStage(null)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                fill="#141414"
              />
            </svg>
          </div>

          <svg
            className="onboarding-template__stake9__success"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M44 22.1602V24.0002C43.9975 28.3131 42.601 32.5096 40.0187 35.9639C37.4363 39.4182 33.8066 41.9452 29.6707 43.168C25.5349 44.3909 21.1145 44.244 17.0689 42.7494C13.0234 41.2548 9.56931 38.4924 7.22192 34.8744C4.87453 31.2563 3.75958 26.9763 4.04335 22.6728C4.32712 18.3693 5.99441 14.2729 8.79656 10.9944C11.5987 7.71586 15.3856 5.43098 19.5924 4.48051C23.7992 3.53004 28.2005 3.96489 32.14 5.72022"
              stroke="#099137"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M44 8L24 28.02L18 22.02"
              stroke="#099137"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div className="onboarding-template__stake9__title">
            Staking Successful
          </div>
          <div
            className="onboarding-template__stake9__subtitle pointer"
            onClick={() => setStakeStage(null)}
          >
            Unlock Access
          </div>
        </div>
      )}
    </div>
  );
}
